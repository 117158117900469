.top-ui {
  width: 100%;
  padding-bottom: 20px;
}

.top-ui form.generic .field {
  width: 60%;
  float: left;
  padding: 0;
}

.top-ui form.generic .field .input {
  width: 60%;
}

.top-ui form.generic .field input[type="text"] {
  width: 100%;
}

.top-ui form.generic .field label, form.generic .field .label {
  width: 38%;
  //text-transform: uppercase;
  font-size: 12px;
  padding-right: 2%;
}

.top-ui form.generic .form-actions {
  width: 20%;
  padding: 0;
  float: left;
}

.top-ui form.generic .form-actions .input {
  padding: 0;
  display: inline-block;
}

.dataTables_wrapper {
  margin-top: 0px;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: @background-shade;
}

table.dataTable tbody tr td {
  line-height: 1.5;
}

td.group-head {
  border-color: @medimo-75;
  color: #FFF;
  background-color: #CCC;
}

table.dataTable tbody td.graphic {
  padding: 0;
  margin: 0;
}

table.dataTable th, table.dataTable td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a, div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; // To make 'width' on the 'td a' and 'td span' element do something (no max-width on line elements), which enables ellipsis
  }
}

table.dataTable.display tbody td.graphic {
  border-top: 0px;
}

table.dataTable tbody td.graphic figure {
  margin-top: 0px;
  margin-bottom: 0px;
}

div.DTTT_container {
  float: none;
  margin-bottom: 2em;
}

.dataTables_wrapper .dataTables_info {
  margin-bottom: 1em;
  text-transform: uppercase;
  font-size: 0.722rem !important;
  color: #999;
}

.dataTables_scrollBody > table tr td:nth-child(3) {
  max-width: 200px;
}

.dataTables_scrollBody > td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dataTables_icons_right {
  float: right;
  line-height: 32px;
  padding-right: 3px;

  a {
    color: @mmdonkerblauw !important;
  }
}

table.dataTable tr {
  height: 40px;
  border-bottom: 1px solid #E8EDF2;
  line-height: 1.75;
  color: #1C4B84;
}
table.dataTable tr th {
  color: black;
}
table.dataTable {
  margin-bottom: 0;
}
