.form-status-new {
  #amounts input,  #amountsfull input, #minimumWaitingDays {
    width: 50px;
  }

  .hidden {
    display: none
  }

  #amountsfull {
    td {
      padding: 0 2px 4px;
      vertical-align: middle;
    }
  }

  .calendar {
    display: inline-block;
    background-image: url(/public/assets/images/calendar.gif);
    background-size: contain;
    vertical-align: middle;
    width: 13px;
    height: 12px;
  }

  .kompas {
    display: inline-block;
    background-image: url(/public/assets/images/kompas.png);
    background-size: contain;
    vertical-align: text-bottom;
    width: 17px;
    height: 17px;
  }

  .dateTimeLabel {
    display: inline-block;
    width: 120px;
  }

  .amount {
    width: 50px;
  }

  .info {
    width: 571px;
  }

  .dateTimeBox {
    margin-top: 3px;
  }
}

.form-status-checkboxes {
  float: left;
  line-height: 34px;
  margin-left: 15px;
}

.features {
  font-size: 10px;
  margin-bottom:3px;

  .warning {
    color: @mmred;
    font-size: inherit;
    font-weight: 600;
  }
}

.statusrows {

  .provisionalstatus a, .calculated {
    color: darkorange;
  }
}

.orange {
  color: darkorange;
}

.red {
  color: @mmred;
}

.blue {
  color: @mmblauw;
}

.green {
  color: @mmgreen;
}

.darkgrey {
  color: @mmdonkergrijs;
}

.lightgrey {
  color: @mmlichtgrijs;
}

// The datetime selector leaves <div>'s in the DOM when using an AJAX request for form posts.
// This is probably a bug in the datetime selector.
// A quick hack is to hide the element that remain.
.xdsoft_datetimepicker.xdsoft_noselect {
  display:none;
}
