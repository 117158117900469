// Make css more specific with html tag in front
html {

  /* Overwrite jquery-ui.css */
  .ui-button {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .ui-button,
  .ui-button:link,
  .ui-button:visited,
  .ui-button:hover,
  .ui-button:active {
    margin-bottom: 3px;
  }

  // White line to the left of a button-set dropdown button
  a.ui-button.ui-button-icon-only {
    border-left: 1px solid white;
  }

  // White background on active button-set dropdown button
  a.ui-button:active.ui-button-icon-only {
    border-left: 1px solid white;
    background: #fff;
  }

  /* Component containers
  ----------------------------------*/

  .ui-widget input,
  .ui-widget select,
  .ui-widget textarea,
  // Overwrite rules above to have 'ui-widget button.fa' use FontAwesome
  // Used in benchmark
  .ui-widget button.fa {
    font-family: FontAwesome;
  }

  .ui-widget.ui-widget-content {
    border: 1px solid #e8e9e8;
  }

  .ui-widget-content {
    border: 1px solid #e8e9e8;
    background: #fff;
    color: #000;
  }

  .ui-widget-content a {
    color: #000;
  }

  .ui-widget-header {
    border: 1px solid #e8e9e8;
    background: #fff;
    color: #222;
  }
  .ui-widget-header a {
    color: #222;
  }

  /* Interaction states
  ----------------------------------*/
  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default,
  .ui-button,
  // We overwrite with body, as jquery uses html here for specificity
  body .ui-button.ui-state-disabled:hover,
  body .ui-button.ui-state-disabled:active {
    border: 1px solid @mmblauw;
    background: @mmblauw;
    color: #fff;
  }

  .ui-state-default a,
  .ui-state-default a:link,
  .ui-state-default a:visited,
  a.ui-button,
  a:link.ui-button,
  a:visited.ui-button,
  .ui-button {
    color: #fff;
  }

  .ui-state-hover,
  .ui-widget-content .ui-state-hover,
  .ui-widget-header .ui-state-hover,
  .ui-state-focus,
  .ui-widget-content .ui-state-focus,
  .ui-widget-header .ui-state-focus,
  .ui-button:hover,
  .ui-button:focus {
    border: 1px solid @mmblauw;
    background: @mmblauw;
    color: #fff;
  }

  .ui-state-hover a,
  .ui-state-hover a:hover,
  .ui-state-hover a:link,
  .ui-state-hover a:visited,
  .ui-state-focus a,
  .ui-state-focus a:hover,
  .ui-state-focus a:link,
  .ui-state-focus a:visited,
  a.ui-button:hover,
  a.ui-button:focus {
    color: #fff;
  }

  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active,
  a.ui-button:active,
  .ui-button:active,
  .ui-button.ui-state-active:hover {
    border: 1px solid @mmblauw;
    background: @mmblauw;
    color: #fff;
    text-decoration: none;
  }

  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    border: 1px solid #f0a911;
    background: #fbf9ee;
    color: #363636;

    .fa {
      color: @mmdonkerblauw;
    }
  }

  .ui-state-highlight a,
  .ui-widget-content .ui-state-highlight a,
  .ui-widget-header .ui-state-highlight a {
    color: #363636;
  }

  .ui-state-error,
  .ui-widget-content .ui-state-error,
  .ui-widget-header .ui-state-error {
    border: 1px solid @mmred;
    background: #fef1ec;
    color: @mmred;
  }

  .ui-state-error a,
  .ui-widget-content .ui-state-error a,
  .ui-widget-header .ui-state-error a {
    color: @mmred;
  }
  .ui-state-error-text,
  .ui-widget-content .ui-state-error-text,
  .ui-widget-header .ui-state-error-text {
    color: @mmred;
  }

  .a-ui-state-disabled,
  .ui-state-disabled,
  .ui-widget-content .ui-state-disabled,
  .ui-widget-header .ui-state-disabled {
    opacity: .35;
    filter:Alpha(Opacity=35); /* support: IE8 */
    background-image: none;
  }

  .a-ui-state-disabled {
    cursor: text;
  }

  /* Icons
  ----------------------------------*/
  .ui-icon,
  .ui-widget-content .ui-icon {
    background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_555555_256x240.png");
  }

  .ui-widget-header .ui-icon {
    background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_555555_256x240.png");
  }

  // Align icon with text in widget notification box
  div.ui-widget p .ui-icon {
    margin-top: 0px;
  }

  table .ui-widget {
    line-height: 16px;
  }

  .ui-state-default .ui-icon {
    background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_ffffff_256x240.png");
  }

  .ui-state-hover .ui-icon,
  .ui-state-focus .ui-icon,
  .ui-button:hover .ui-icon,
  .ui-button:focus .ui-icon {
    background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_ffffff_256x240.png");
  }

  .ui-state-active .ui-icon,
  .ui-button:active .ui-icon {
    background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_555555_256x240.png");
  }

  .ui-state-highlight .ui-icon,
  .ui-button .ui-state-highlight.ui-icon {
    //background-image: url("../lib/jquery-ui/images/ui-icons_217bc0_256x240.png");
    background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_555555_256x240.png");
  }

  .ui-state-error .ui-icon,
  .ui-state-error-text .ui-icon {
    background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_cc0000_256x240.png");
  }

  .ui-button .ui-icon {
    background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_ffffff_256x240.png");
  }

  .ui-corner-all,
  .ui-corner-top,
  .ui-corner-left,
  .ui-corner-tl {
    border-top-left-radius: 0px;
  }

  .ui-corner-all,
  .ui-corner-top,
  .ui-corner-right,
  .ui-corner-tr {
    border-top-right-radius: 0px;
  }

  .ui-corner-all,
  .ui-corner-bottom,
  .ui-corner-left,
  .ui-corner-bl {
    border-bottom-left-radius: 0px;
  }

  .ui-corner-all,
  .ui-corner-bottom,
  .ui-corner-right,
  .ui-corner-br {
    border-bottom-right-radius: 0px;
  }

  .ui-widget-overlay {
    background: #aaa;
    opacity: .3;
    filter: Alpha(Opacity=30); /* support: IE8 */
  }

  .ui-widget-shadow {
    margin: -8px 0 0 -8px;
    padding: 8px;
    background: #aaa;
    opacity: .3;
    filter: Alpha(Opacity=30); /* support: IE8 */
    border-radius: 0px;
  }

  .ui-tabs {
    padding: 0px;
  }

  .ui-tabs .ui-tabs-nav {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  // Remove bottom border of all tabs
  .ui-tabs .ui-tabs-tab {
    border-bottom: 0;
  }
}
