/* 12 columns fluid grid */
.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6,
.grid_7,
.grid_8,
.grid_9,
.grid_10,
.grid_11,
.grid_12 {
  display: block;
  float: left;
  position: relative;
  margin: 0;
}

.row {
  max-width: 1024px;
  margin: 0 auto;
  box-sizing: border-box;
}

.grid_1 {
  width: 8.333333%;
}

.grid_2 {
  width: 16.666666%;
}

.grid_3 {
  width: 25%;
}

/* col 1 third */
.grid_4 {
  width: 33.333333%;
}

.grid_5 {
  width: 41.666666%;
}

/* col 1 half */
.grid_6 {
  width: 50%;
}

.grid_7 {
  width: 58.333333%;
}

/* col 2 thirds */
.grid_8 {
  width: 66.666666%;
}

.grid_9 {
  width: 75%;
}

.grid_10 {
  width: 83.333333%;
}

.grid_11 {
  width: 91.666666%;
}

.grid_12 {
  width: 100%;
}

.padding_12 {
  padding: 12%;
}

@media (max-width: 800px) {

  /* remove grid */
  .grid_1,
  .grid_2,
  .grid_3,
  .grid_4,
  .grid_5,
  .grid_6,
  .grid_7,
  .grid_8,
  .grid_9,
  .grid_10,
  .grid_11,
  .grid_12 {
    width: 100%;
  }
}