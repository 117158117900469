/* paragraphs are used to render the intro and linked paragraphs of a page */
.paragraphs {
  margin-top: 15px; /* same as common content elements from base.css */
}

.paragraphs:first-child {
  margin-top: 0;
}

.paragraphs ol,
.paragraphs ul {
  overflow: auto; /* prevent bullets from falling out when next to a floating img */
}

/* figures for images */
figure.img {
  margin-bottom: 10px;
}

figure.img.left {
  float: left;
  margin-right: 15px;
  max-width: 250px;
}

figure.img.right {
  float: right;
  margin-left: 15px;
  max-width: 250px;
}

figure.img img {
  display: block;
  width: 100%;
  height: auto;
}

/* clearfix for clearing elements that contain floating elements */
.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

/* class for hiding anything */
.hidden {
  display: none;
}

/* float element left */
.push-left {
  float: left;
}

/* float element right */
.push-right {
  float: right;
}

/* classes to align text */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* hide redoute input */
input.redoute {
  display: none !important;
}
