.lsp-switch-box {
  float: left;
  height: 17px;
  margin: 3px 8px 0 0;
  width: 15px;
}

.lsp-switch {
  position: relative;
  border: 1px solid #ccc;
  box-sizing: content-box;
  cursor: pointer;
  font-size: 12px;
  height: 12px;
  text-align: center;
  width: 12px;
}

.lsp-switch-no {
  color: red;
  background-color: #fff9f9;
  border-color: #ff8787;
}

.lsp-switch-yes {
  color: green;
  background-color: #f9fff9;
  border-color: #5bb361;
}

.lsp-switch-neutral {
  color: grey;
  background-color: @mmshade;
}

.lsp-switch span {
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.25px; /* -0.25px correctly aligns the icon vertically in chrome, firefox, safari, IE 11 and Edge */
  width: 12px;
  height: 12px;
}
