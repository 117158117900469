/* reset all elements */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
main, footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: @mmfontsize;
  vertical-align: baseline;
  background: transparent;
}

/* html5 elements */
article, aside, details, figcaption, figure,
main, footer, header, hgroup, menu, nav, section {
  display: block;
}

/* html / body */
html, body {
  height: 100%;
}

html.touch {
  /* remove iOs tap highlight color */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  background-color: white;
  //background-color: lighten(@mmblauw, 35%);
}

wrapper {
  /* always show vertical scrollbar to prevent horizontal jumping */
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  overflow: auto !important;
}

/* set font */
body,
input,
select,
textarea,
pre {
  color: black;
  font-family: @mmfont;
  font-size: @mmfontsize;
  line-height: @mmfontlineheight;
}

/* anchors */
a {
  color: @mmdonkerblauw;//darken(@mmblauw, 30%);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

/* remove focus outline */
a:focus {
  outline: 0;
}

/* block images in links */
a img {
  border: 0;
  //display: block;
  cursor: pointer;
}

/* set the same top margin for common content elements */
p, ol, figure, table, dl, blockquote, q {
  margin: 15px 0 0 0;
}

///* remove top margins for first element */
//h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child,
//p:first-child,
//ul:first-child, ol:first-child, li:first-child,
//table:first-child, blockquote:first-child, q:first-child, dl:first-child {
//  margin-top: 0;
//}

/* reset lists */
li {
  padding: 0;
  margin: 0;
}

ul.bulletlist li {
  font-size: 12px;
  line-height: 23px;
      padding-left: 7px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
}

ul {
  padding: 0px 0px 0px 2px;
  margin: 0px;
  list-style-type: disc;
  list-style-position: inside;
}

/* table */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* table header cells */
table thead td {
  font-weight: normal;
  text-align: left;
}

table th {
  text-align: left;
}

/* table cells */
table th,
table td {
  min-height: 39px;
  padding: 2px 0;
  vertical-align: top;
}
pre {
  line-height: 22px;
  padding: 2px 0;
  vertical-align: top;
}

.ruletimes td {
  padding: 0px 0 4px 0;
  vertical-align: middle;
}

///* form elements */
//input,
//select,
//textarea {
//  font-size: 100%;
//}

html.touch input[type="text"],
html.touch input[type="password"],
html.touch input[type="button"],
html.touch input[type="submit"],
html.touch select,
html.touch textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

textarea {
  resize: vertical;
}

input:focus {
  outline-color: #4a95be;
}

input[type="text"]:disabled, input[type="password"]:disabled {
  background-color: #eee;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  margin: 0 5px 0 0;
  line-height: 20px;
  top: 1px;
}

label.radiolabel {
  margin-right: 5px;
}

/* super en subscript */
sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

sub {
  top: 0.4em;
}

/* textarea */
textarea {
  resize: vertical;
}

//hr {
//    margin-top: 15px;
//	margin-bottom: 15px;
//	border: 0px;
//	border-bottom: 1px solid #111;
//}

.ui-buttonset {
  white-space: nowrap;
}

/*
 * Chat styles
 */

.chat {
	  margin: 0 auto;
	  max-width: 800px;

	p {
      margin: 0 0 1em;
      border-radius: 2px;
      padding: 0.5em 1em;
      background: @mmlichtgrijs;
      max-width: 85%;
      clear: both;
      position: relative;

      &.them {
        float: left;

        &::after {
	      content: "";
	      position: absolute;
	      left: -0.5em;
	      bottom: 0;
	      width: 0.5em;
	      height: 1em;
	      border-right: 0.5em solid @mmlichtgrijs;
	      border-bottom-right-radius: 1em 0.5em;
	    }
	  }

	  &.me {
	    float: right;
	    background-color: @mmgrijs;

	    &::after {
	      content: "";
	      position: absolute;
	      right: -0.5em;
	      bottom: 0;
	      width: 0.5em;
	      height: 1em;
	      border-left: 0.5em solid @mmgrijs;
	      border-bottom-left-radius: 1em 0.5em;
	    }
	  }

  	  &.system {
	    float: left;
	    background-color: @medimoblue;
	    color: @mmzwart;
	  }

	}
}

.pointer {
  cursor: pointer;
}

/*****************************************************************************************************
 * Responsive
 *****************************************************************************************************/

@media (max-width: 960px) {

  /* prevent iOs font resizing on orientation change */
  html {
    -webkit-text-size-adjust: none;
  }
}
