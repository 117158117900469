/* page content */
.page-content {
  background-color: #ffffff;
  //margin: 0 auto;
  padding: 40px;
  position: relative;
  max-width: 1024px;
  box-shadow: 0 0px 20px rgba(100, 100, 100, 0.1);
  box-sizing: border-box;
}

select {
  padding-right: 11px;
}

body {
  padding-bottom: 0 !important;

  & * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/* hide tables that are not yet datatabled*/
.js table.has-datatable {
  visibility: hidden;
}

.js table.has-datatable.dataTable {
  visibility: visible;
}

h1 {
  margin-bottom: 20px;
}

.print .dataTables_wrapper {
  margin-top: 0;
}

body.print .dataTables_close-print {
  display: block;
}

/* datatable buttons */
button.dt-button,
div.dt-button,
a.dt-button {
  //border-radius: 2px;
  padding: 3px 8px;
}

button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover {
  color: @primary !important;
}

a.buttons-collection {
  padding-right: 1px;
}

a.buttons-collection span {
  padding-right: 17px;
  background: url(/node_modules/datatables.net-dt/images/sort_desc_disabled.png) no-repeat right -4px;
}

a.buttons-collection:hover span {
  background-image: url(/node_modules/datatables.net-dt/images/sort_desc.png);
}

/* button popup */
div.dt-button-background {
  background: #fff;
  display: none !important;
}

div.dt-button-collection {
  background-color: #fff;
  padding: 3px 0;
  width: auto;
  //max-width: 300px;
  border-radius: 0;
}

div.dt-button-collection button.dt-button,
div.dt-button-collection div.dt-button,
div.dt-button-collection a.dt-button {
  border: 0;
  background: #fff;
  box-shadow: 0 0;
  border-radius: 0;
}

div.dt-button-collection button.dt-button:hover,
div.dt-button-collection div.dt-button:hover,
div.dt-button-collection a.dt-button:hover {
  color: @primary !important;
  border: 0;
  background: none;
}

div.dt-button-collection a.dt-button.is_active {
  font-weight: 600;
}

table.dataTable tbody td {
  border-top: 1px solid @background-contrast;
}

/* cellpadding */
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 10px;
}

table.dataTable thead th.sorting_asc,
table.dataTable thead td.sorting_asc,
table.dataTable thead th.sorting_desc,
table.dataTable thead td.sorting_desc,
table.dataTable thead th.sorting,
table.dataTable thead td.sorting {
  padding-right: 18px;
}

table.dataTable td.right,
table.dataTable th.right {
  text-align: right;
}

/* table header */
table.dataTable thead .sorting .sorting_asc .sorting_desc {
  color: @primary;
}

table.dataTable thead th {
  vertical-align: middle;
}

/* hover over row */
table.dataTable tbody tr[role="row"]:hover,
table.dataTable tbody tr[role="row"].odd:hover,
table.dataTable tbody tr[role="row"].even:hover {
  //background-color: @mmlichtgrijs;
}

/* sorting column */
table.dataTable tbody tr[role="row"].odd > .sorting_1,
table.dataTable tbody tr[role="row"].even > .sorting_1,
table.dataTable tbody tr[role="row"].odd > .sorting_2,
table.dataTable tbody tr[role="row"].even > .sorting_2,
table.dataTable tbody tr[role="row"].odd > .sorting_3,
table.dataTable tbody tr[role="row"].even > .sorting_3 {
  background-color: @medimo-5;
}

/* all rows */
table.dataTable tbody tr.odd,
table.dataTable tbody tr.even {
  background-color: transparent;
}

/* group header */
table.dataTable tbody tr.group-header td {
  border: 0;
  color: #000;
  background-color: @red;
  cursor: pointer;
}

table.dataTable tbody tr.group-header td span.total {
  font-weight: normal;
}

/* group footer */
table.dataTable tbody tr.group-footer {
  background: transparent;
}

table.dataTable tbody tr.group-footer td.sum {
  border-top: 1px solid #000;
  font-weight: 600;
}

/* info box */
.dataTables_wrapper .dataTables_info {
  text-transform: none;
  //color: #666;
  font-size: 100%;
  //font-weight: 600;
}

/* multi select custom search */
.dataTables_filter {
  margin: 0 20px 10px 0;
  float: left;
}

/* page nr selector */
.dataTables_length select {
  width: 60px;
}

/* toolbar */
.toolbar {
  background: @red;
  padding: 10px 10px 0 10px;
  margin-bottom: 10px;
}

.dt-button.fa {
  float: right;
  border: 0;
  background: none;
  font-size: 15px;
  position: relative;

  &:hover {
    background: none !important;
    border: 0 !important;
  }

  span {
    display: none;
  }
}

.ios .toolbar select,
.toolbar input {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  height: 29px;
  line-height: 29px;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background-image: -webkit-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -moz-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -o-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: linear-gradient(#eeeeee 1%, #ffffff 15%);
  cursor: text;
  font-size: 100%;
  font-size: 13px;
  color: #000;
  font-family: @mmfont;
  line-height: normal;
}

.toolbar .select2-container--default .select2-selection--multiple {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  //height: 29px;
  line-height: 29px;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background-image: -webkit-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -moz-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -o-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: linear-gradient(#eeeeee 1%, #ffffff 15%);
  cursor: text;
  font-size: 100%;
  font-size: 13px;
  color: #000;
  font-family: @mmfont;
  line-height: normal;
  border-radius: 0;
}

.toolbar .select2-container .select2-search--inline .select2-search__field {
  margin: 0;
  padding: 3px 5px 0px 5px;
}

.toolbar .select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
}

.dataTables_wrapper .toolbar .dataTables_length {
  margin-top: 0;
  margin-right: 5px;
}

.toolbar .select2-search {
  padding: 0;
}

.toolbar .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
}

.toolbar .select2-container .select2-selection--multiple {
  min-height: 29px;
}

.toolbar .multiple input {
  width: 100px;
}

.toolbar .multiple .between {
  display: inline-block;
  width: 20px;
  text-align: center;
}

.toolbar.cf {
  .dataTables_advanced-search.is_open + div.dataTables_length {
    display: none;
  }
}

div.dt-buttons {
  top: 11px;
  float: none;

  .layout-button, .layout-button:hover, .layout-button:focus,
  .buttons-collection, .layout-button:hover, .layout-buttonc:focus {
    float: left;
    height: 29px;
    margin: 0;
    padding: 0 5px;
    text-shadow: none;
    border: 1px solid #aaa;
    background-color: #fff;
    background-image: none;
    position: relative;
    top: -1px;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 27px;

    span {
      padding-right: 20px;
      margin-left: 10px;
      color: #333333;
      background: none;

      &:after {
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        right: 10px;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
        content: '';
      }
    }
  }
}

.dataTables_wrapper .dataTables_info {
  margin-bottom: 10px;
  padding-top: 10px;
}

.dataTables_wrapper .dataTables_filter {
  margin: 0 10px 10px 0;
}

/* multi select custom filter */
.dataTables_advanced-search {
  margin: 0 10px 10px 0;
  padding-left: 10px;
  border-left: 1px solid #aaa;
  float: left;
  position: relative;
  overflow: hidden;
}

.dataTables_advanced-search.is_open {
  overflow: visible;
}

.dataTables_advanced-search .dataTables_advanced-search-trigger {
  display: inline-block;
  line-height: 29px;
  cursor: pointer;
  color: #000;
}

.dataTables_advanced-search .dataTables_advanced-search-trigger:hover,
.dataTables_advanced-search .dataTables_advanced-search-trigger:focus {
  text-decoration: underline;
  color: @primary;
  white-space: nowrap;
}

.dataTables_advanced-search .dataTables_advanced-search-trigger.close {
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 20px;
  display: none;
  padding: 0 10px;
}

.dataTables_advanced-search.is_open .dataTables_advanced-search-trigger.close {
  display: block;
}

.dataTables_advanced-search .filter {
  line-height: 29px;
  padding-top: 10px;
  display: block;
  position: absolute;
  top: -200px;
}

.dataTables_advanced-search .filter span.input {
  float: right;
  margin-left: 10px;
  white-space: nowrap;
}

.dataTables_advanced-search input.error {
  border-color: @mmred;
}

.dataTables_advanced-search.is_open .filter {
  position: relative;
  top: 0;
}

.dataTables_advanced-search .filter:first-child {
  padding-top: 0;
}

.dataTables_advanced-search select {
  min-width: 226px;
}

.dataTables_advanced-search {
  float: right;
  margin-left: 10px;
}

/* mpn */
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #ddd !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #1C4B84!important;
  color: white;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 24px;
  padding: 0.5rem 0.75rem;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: @primary;
  border-radius: 3px;
  margin-left: 2px;
  margin: 0 0.0722rem;
  border: none;
  background-color: @medimo-10;
}

/* print */
.print .wrapper .nav {
  display: none;
}

.print .wrapper {
  margin: 0;
}

.print .toolbar.cf {
  display: none;
}

.print .dt-buttons {
  display: none;
}

.print .page-content {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  padding-top: 40px;
  margin: 0;
}

.print .page-content > * {
  display: none;
}

.print .page-content > .dataTables_wrapper {
  display: block;
}

.print .page-content > .dataTables_wrapper > * {
  display: none;
}

.print .page-content > .dataTables_wrapper > table {
  display: block;

  width: 100%;

  tr, td, th, tbody, thead {
    width: auto !important;
  }
}

.print .page-top {
  display: none;
}

.dataTables_close-graph {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: none;
}

.dataTables_close-print {
  position: absolute;
  top: -40px;
  left: -40px;
  right: -40px;
  display: none;
  text-align: center;
}

@media only print {
  .dataTables_close-graph a, .dataTables_close-print a {
    display: none !important;
  }
}

.dataTables_close-graph a,
.dataTables_close-print a {
  display: block;
  padding: 10px 20px 0;
  text-decoration: none;
  color: #666;
}

.print .page-content > .dataTables_wrapper > .dataTables_close-graph,
.print .page-content > .dataTables_wrapper > .dataTables_close-print {
  display: block;
}

/* highcharts */
#chart {
  min-height: 400px;
}

#chart,
.showchart .page-content > * {
  display: none !important;
}

.showchart .page-content > .dataTables_close-graph {
  display: block !important;
}

ul li {
  line-height: 20px;
}

.showchart .page-content {
  display: table;
  width: 1024px;
}

.showchart .page-content > #chart {
  display: table-cell !important;
  vertical-align: middle;
}

.showchart .page-top {
  display: none;
}

.highcharts-container {
  margin: 0 auto;
}

/* highcharts popup */
.DTTT_print_info {
  height: auto;
  box-sizing: border-box;
}

.DTTT_print_info p {
  margin: 20px 0;
}

/* highcharts link to highcharts.com */
svg > text[text-anchor="end"]:last-child {
  display: none;
}

/* no-search column cells fade out when searching */
td.no-search {
  opacity: 0.5;
}

th.no-search {
  color: #777;
}

table.dataTable thead .sorting.no-search {
  color: #70B7CD;
}

.hide-desktop {
  display: none;
}

.hide-mobile {
  display: block;
}

@media (max-width: 800px) {

  .hide-desktop {
    display: block;
  }

  .hide-mobile {
    display: none;
  }

  .page-content {
    padding: 20px;
    width: 100%;
  }
}

@media (min-width: 800px) {
  html {
    overflow-y: scroll;
  }
}

// sticky footer
* {
  margin: 0;
}

html, body {
  height: 100%;
}

.wrapper {
  min-height: 100%;
  height: auto !important; /* This line and the next line are not necessary unless you need IE6 support */
  height: 100%;
  margin: 0 auto -65px; /* the bottom margin is the negative value of the footer's height */
}

.footer, .push {
  height: 65px; /* .push must be the same height as .footer */
}

.footer {
  padding: 10px;
  text-align: center;
  position: relative;
}

.nav {
  background: @mmblauw;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  box-shadow: 0 0px 20px rgba(100, 100, 100, 0.1);
  color: #FFF;

  a {
    color: #FFF;
  }

  ul.icons {
    text-align: right;
    float: right;
    margin: 0;
    margin-right: 10px;
    padding: 0;
    li {
      list-style-type: none;
      display: inline-block;
      font-size: 16px;
      margin-left: 10px;

      a {
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }
  }
}

ul.icons {
  margin: 0;
  margin-right: 10px;
  padding: 0;
  display: inline-block;
  li {
    list-style-type: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 10px;

    a {
      width: 16px;
      height: 16px;
    }
  }
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr, .ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-radius: 0;
}

.kbn_active {
  font-weight: bold !important;
}

//form hr {
//  margin: 10px 0;
//}

.push-380 {
  min-height: 486px;
  margin-top: 10px;
}

.push-480 {
  min-height: 480px;
  margin-top: 10px;
}

table.dataTable tbody tr.odd[role="row"] > .sorting_1, table.dataTable tbody tr.even[role="row"] > .sorting_1, table.dataTable tbody tr.odd[role="row"] > .sorting_2, table.dataTable tbody tr.even[role="row"] > .sorting_2, table.dataTable tbody tr.odd[role="row"] > .sorting_3, table.dataTable tbody tr.even[role="row"] > .sorting_3 {
  background-color: inherit;
}

ul.navigation {
  margin: 0;
  padding: 0;

  &.single-column {
    li {
      float: none;
    }
  }

  li {
    background-color: @mmblauw;
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
    list-style-type: none;
    position: relative;

    width: 350px;
    float: left;
    margin-right: 10px;

    a {
      display: block;
      padding-left: 10px;
      height: 100%;
      //width: ;
      margin-left: 40px;
      line-height: 40px;
      background-color: @mmlichtgrijs;
      color: @mmzwart;
    }
  }
}

table.dataTable thead .sorting .sorting_asc .sorting_desc, table.dataTable tbody a {
  color: @mmdonkerblauw; //darken(@mmblauw, 30%);
}

.sidea {
  clear: none;
  width: 242px;
  float: left;
  margin: 15px 10px 0px 0px;
  position: relative;
}

#main2 {
  clear: both;
}

.clear {
  height: 0px;
  overflow: hidden;
  clear: both;
}

#side {
  clear: left;
  float: left;

}

#header.patient {
  h1 {
    margin-bottom: 10px;

    a {
      font-size: @mmfontsize;
      font-weight: 600;

      img {
        display: inline;
      }
    }
  }

  span.meta {
    font-size: @mmfontsize;
    margin-bottom: 5px;
    font-weight: 600;
    display: block;
  }
}

h1 {
  a {
    position: relative;

    img {
      display: inline;
    }
  }
}

//.badge {
//  position: absolute;
//  top: -9px;
//  left: -17px;
//  background: radial-gradient(5px -9px, circle, white 8%, @mmred 26px);
//  background-color: @mmred;
//  border: 2px solid white;
//  border-radius: 12px;
//  -webkit-box-shadow: 1px 1px 1px black;
//  -moz-box-shadow: 1px 1px 1px black;
//  box-shadow: 1px 1px 1px black;
//  color: white;
//  font: bold 12px/9px;
//  padding: 2px 3px 2px 3px;
//  text-align: center;
//
//  &.auto {
//    position: relative;
//    margin-left: 5px;
//    padding: 0px 8px;
//    top: -4px;
//    left: 0;
//  }
//}
//
//.badgecontainer {
//  position: relative;
//  display: inline;
//  top: 9px;
//  left: 30px;
//}

.kenmerken {
  font-size: 10px;
}

.kenmerkenrood {
  font-size: 10px;
  color: @mmred;
}

.kenmerkengrijs {
  font-size: 10px;
  color: @mmdonkergrijs;
}

.kenmerkengroen {
  font-size: 10px;
  color: @mmgreen;
}

.postscript {
  font-size: 10px;
  color: @mmdonkergrijs;
  margin-bottom: 1px;
}

.medicatieopm {
  color: @mmred;
}

.footernav {
  clear: both;
  padding-top: 15px;
  font-size: 32px;
  a {
    color: @mmdonkergrijs;
    &:hover {
      color: @mmdonkerblauw; //darken(@mmblauw, 30%);
    }
  }
}

#toolbar {
  padding: 0px;
  display: inline;
}

table.medicatie {
  td {
    line-height: 24px;
    text-align: left;
    vertical-align: top;
    margin: 0px;
    padding: 0px;
    -webkit-text-size-adjust: none;
  }
}

tr.sideline {
  td {
    line-height: 14px;
    padding: 0 0 4px 0;
  }
}

.patientnaam {
  font-weight: 600;
}

table td.aantalmedicatie {
  text-align: right;
  white-space: nowrap;
}

#extend_session_wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
}

#extend_session {
  width: 400px;
  height: 200px;
  position: absolute;
  margin-left: -200px;
  margin-top: -80px;
  top: 50%;
  left: 50%;
  background: #FFF;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 15px;
  text-align: center;
  line-height: 21px
}

#extend_session span#session_time_remaining {
  font-family: @mmfont;
  padding: 5px;
  background-color: #eee;
}

div.gobitleft {
  width: 120px;
  display: inline-block;
}

.ajaxloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  //opacity: 0.3;
  background: rgba(255, 255, 255, 0.3);
}

.ajaxloader img {
  z-index: 1000;
}

.ajaxloader div {
  //background: url('/assets/images/spinner.gif') no-repeat;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
}

div.tdrdaily {
  h3 {
    margin: 15px 0;
  }

  a.tdrdailypatient {
    width: 125px;
    display: inline-block;
    text-decoration: none;
    margin-right: 5px;
    margin-bottom: 10px;
    position: relative;
    height: 160px;

    span.picture {
      background-size: contain;
      width: 125px;
      display: block;
      height: 125px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    span.label {
      position: absolute;
      top: 130px;
      text-align: center;
      display: block;
    }
  }
}

.ui-tabs-panel {
  min-height: 433px;
}

.ui-tabs .ui-tabs-nav .ui-state-default {
  background: @mmshade;
  border-color: @mmborder;
  .ui-tabs-anchor {
    color: #000;
  }
}

.ui-tabs .ui-tabs-nav .ui-state-default.ui-state-active {
  background: #ffffff;
  border-color: @shaded-blue;
}

.menuset {
  z-index: 1;
  display: none;
}

.ui-menu {
  position: absolute;
  white-space: nowrap;

  li.ui-menu-item {
    margin: 0;
    padding: 1px;
    border: 0;
    list-style-type: none;
    > * {
      display: block;
      padding: 3px 1em 3px .4em;
    }
  }
  li.ui-menu-item.ui-state-focus {
    border: 1px solid @mmblauw;
    padding: 0px;
  }
}

.filter-row {
  .filter-add {
    font-size: 1.2em;
  }

  .filter-item {
    padding-right: 10px;

    select, input[type=text] {
      width: 100%;
    }
  }
}

.row.add-filter {
  margin-top: 15px;

  .button.a-filter {
    float: right;
    width: 22px;
    height: 32px;
    padding: 0px;
    text-align: center;
    line-height: 36px;
    top: 2px;
  }

  .filter-options {
    padding: 10px;
    margin-right: 10px;
    background-color: @background-contrast;
  }
}

.datatableswrapper {
  width: 100%;
  display: none;
}

.medimomodal-wrapper {
  display: block;
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  bottom: -10px;
  left: 0;
  right: 0;
  min-height: 100%;
  width: 100%;
  z-index: 100;
}

.medimomodal {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  width: 496px;
  max-width: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
}

.medimomodal.wide {
  width: 100%;
  max-width: 1920px;
}

.medimomodal-header {
  padding: 16px 20px;
  height: 45px;
  background: #0472B5;
  color: #FFF;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;

  h1, h2, h3, h4 {
    font-size: inherit;
    padding: 0;
    margin: 10px 0px 5px 0px;
    font-weight: inherit;
    line-height: inherit;
  }
}

.medimomodal-header .right {
  float: right;
  font-size: 120%;
}

.medimomodal-ajax-container {
  background: #fff;
}

.medimomodal-content {
  padding: 20px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  line-height: 18px;
  position: relative;
}

.medimomodal-footer {
  text-align: right;
  background: #FFF;
  display: block;
  position: relative;
  box-sizing: content-box;
  min-height: 32px;
  line-height: 32px;
  padding: 0 20px 20px 20px;
}

.medimomodal-footer a {
  display: inline-block;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  font-weight: normal;
  letter-spacing: normal;
  background: #0472B5;
  color: #fff;
  padding: 8px 16px;
  height: 32px;
  line-height: 16px;
}

.medimomodal-footer a.secondary {
  background: #61A4D3;
  color: #fff;
}

#login-table .smartcard {
  display: none;
}
