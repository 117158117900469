@media print {
  .footer,
  .nav,
  .footernav,
  ul.icons,
  .push,
  #toolbar {
    display: none;
  }
  .page-content {
    margin: 0 !important;
    padding: 0 !important;
  }
}
.top-ui {
  width: 100%;
  padding-bottom: 20px;
}
.top-ui form.generic .field {
  width: 60%;
  float: left;
  padding: 0;
}
.top-ui form.generic .field .input {
  width: 60%;
}
.top-ui form.generic .field input[type="text"] {
  width: 100%;
}
.top-ui form.generic .field label,
form.generic .field .label {
  width: 38%;
  font-size: 12px;
  padding-right: 2%;
}
.top-ui form.generic .form-actions {
  width: 20%;
  padding: 0;
  float: left;
}
.top-ui form.generic .form-actions .input {
  padding: 0;
  display: inline-block;
}
.dataTables_wrapper {
  margin-top: 0px;
}
table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: #F9FAFC;
}
table.dataTable tbody tr td {
  line-height: 1.5;
}
td.group-head {
  border-color: #5579A3;
  color: #FFF;
  background-color: #CCC;
}
table.dataTable tbody td.graphic {
  padding: 0;
  margin: 0;
}
table.dataTable th,
table.dataTable td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
table.dataTable th a,
table.dataTable td a,
table.dataTable th div,
table.dataTable td div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
table.dataTable.display tbody td.graphic {
  border-top: 0px;
}
table.dataTable tbody td.graphic figure {
  margin-top: 0px;
  margin-bottom: 0px;
}
div.DTTT_container {
  float: none;
  margin-bottom: 2em;
}
.dataTables_wrapper .dataTables_info {
  margin-bottom: 1em;
  text-transform: uppercase;
  font-size: 0.722rem !important;
  color: #999;
}
.dataTables_scrollBody > table tr td:nth-child(3) {
  max-width: 200px;
}
.dataTables_scrollBody > td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dataTables_icons_right {
  float: right;
  line-height: 32px;
  padding-right: 3px;
}
.dataTables_icons_right a {
  color: #1C4B84 !important;
}
table.dataTable tr {
  height: 40px;
  border-bottom: 1px solid #E8EDF2;
  line-height: 1.75;
  color: #1C4B84;
}
table.dataTable tr th {
  color: black;
}
table.dataTable {
  margin-bottom: 0;
}
/*
 * File:        TableTools.css
 * Description: Styles for TableTools 2
 * Author:      Allan Jardine (www.sprymedia.co.uk)
 * Language:    Javascript
 * License:     GPL v2 / 3 point BSD
 * Project:     DataTables
 *
 * Copyright 2009-2012 Allan Jardine, all rights reserved.
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * CSS name space:
 *   DTTT                  DataTables TableTools
 *
 * Style sheet provides:
 *   CONTAINER             TableTools container element and styles applying to all components
 *   BUTTON_STYLES         Action specific button styles
 *   SELECTING             Row selection styles
 *   COLLECTIONS           Drop down list (collection) styles
 *   PRINTING              Print display styles
 */
/*
 * CONTAINER
 * TableTools container element and styles applying to all components
 */
div.DTTT_container {
  position: relative;
  float: right;
  margin-bottom: 1em;
}
@media screen and (max-width: 640px) {
  div.DTTT_container {
    float: none !important;
    text-align: center;
  }
  div.DTTT_container:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}
button.DTTT_button,
div.DTTT_button,
a.DTTT_button {
  position: relative;
  display: inline-block;
  margin-right: 3px;
  padding: 5px 8px;
  border: 1px solid #999;
  cursor: pointer;
  *cursor: hand;
  font-size: 0.866em;
  color: black !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 1px 1px 3px #ccc;
  -moz-box-shadow: 1px 1px 3px #ccc;
  -ms-box-shadow: 1px 1px 3px #ccc;
  -o-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;
  /* Generated by http://www.colorzilla.com/gradient-editor/ */
  background: #ffffff;
  /* Old browsers */
  background: linear-gradient(to bottom, #ffffff 0%, #F9FAFC 89%, #F9FAFC 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f9f9f9', GradientType=0);
  /* IE6-9 */
}
/* Buttons are cunning border-box sizing - we can't just use that for A and DIV due to IE6/7 */
button.DTTT_button {
  height: 30px;
  padding: 3px 8px;
}
.DTTT_button embed {
  outline: none;
}
button.DTTT_button:hover,
div.DTTT_button:hover,
a.DTTT_button:hover {
  border: 1px solid #666;
  text-decoration: none !important;
  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  -ms-box-shadow: 1px 1px 3px #999;
  -o-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
  background: #F9FAFC;
  /* Old browsers */
  background: -webkit-linear-gradient(top, #F9FAFC 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #F9FAFC 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #F9FAFC 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #F9FAFC 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #F9FAFC 0%, #e2e2e2 89%, #f4f4f4 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#f4f4f4', GradientType=0);
  /* IE6-9 */
}
button.DTTT_button:focus,
div.DTTT_button:focus,
a.DTTT_button:focus {
  border: 1px solid #426c9e;
  text-shadow: 0 1px 0 #c4def1;
  outline: none;
  background-color: #a3d0ef 100%;
  background-image: -webkit-linear-gradient(top, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  background-image: -moz-linear-gradient(top, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  background-image: -ms-linear-gradient(top, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  background-image: -o-linear-gradient(top, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  background-image: linear-gradient(to bottom, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#a3d0ef', EndColorStr='#a3d0ef');
}
button.DTTT_button:active,
div.DTTT_button:active,
a.DTTT_button:active {
  -webkit-box-shadow: inset 1px 1px 3px #999999;
  -moz-box-shadow: inset 1px 1px 3px #999999;
  box-shadow: inset 1px 1px 3px #999999;
}
button.DTTT_disabled,
div.DTTT_disabled,
a.DTTT_disabled {
  color: #999;
  border: 1px solid #d0d0d0;
  background: #ffffff;
  /* Old browsers */
  background: linear-gradient(to bottom, #ffffff 0%, #F9FAFC 89%, #fafafa 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fafafa', GradientType=0);
  /* IE6-9 */
}
/*
 * BUTTON_STYLES
 * Action specific button styles
 * If you want images - comment this back in

a.DTTT_button_csv,
a.DTTT_button_xls,
a.DTTT_button_copy,
a.DTTT_button_pdf,
a.DTTT_button_print {
    padding-right: 0px;
}

a.DTTT_button_csv span,
a.DTTT_button_xls span,
a.DTTT_button_copy span,
a.DTTT_button_pdf span,
a.DTTT_button_print span {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    padding-right: 30px;
}


a.DTTT_button_csv span { background: url(/public/assets/images/csv.png) no-repeat bottom right; }
a.DTTT_button_csv:hover span { background: url(/public/assets/images/csv_hover.png) no-repeat center right; }

a.DTTT_button_xls span { background: url(/public/assets/images/xls.png) no-repeat center right; }
a.DTTT_button_xls:hover span { background: #f0f0f0 url(/public/assets/images/xls_hover.png) no-repeat center right; }

a.DTTT_button_copy span { background: url(/public/assets/images/copy.png) no-repeat center right; }
a.DTTT_button_copy:hover span { background: #f0f0f0 url(/public/assets/images/copy_hover.png) no-repeat center right; }

a.DTTT_button_pdf span { background: url(/public/assets/images/pdf.png) no-repeat center right; }
a.DTTT_button_pdf:hover span { background: #f0f0f0 url(/public/assets/images/pdf_hover.png) no-repeat center right; }

a.DTTT_button_print span { background: url(/public/assets/images/print.png) no-repeat center right; }
a.DTTT_button_print:hover span { background: #f0f0f0 url(/public/assets/images/print_hover.png) no-repeat center right; }

 */
button.DTTT_button_collection span {
  padding-right: 17px;
}
button.DTTT_button_collection:hover span {
  padding-right: 17px;
}
/*
 * SELECTING
 * Row selection styles
 */
table.DTTT_selectable tbody tr {
  cursor: pointer;
  *cursor: hand;
}
table.dataTable tr.DTTT_selected.odd {
  background-color: #9FAFD1;
}
table.dataTable tr.DTTT_selected.odd td.sorting_1 {
  background-color: #9FAFD1;
}
table.dataTable tr.DTTT_selected.odd td.sorting_2 {
  background-color: #9FAFD1;
}
table.dataTable tr.DTTT_selected.odd td.sorting_3 {
  background-color: #9FAFD1;
}
table.dataTable tr.DTTT_selected.even {
  background-color: #B0BED9;
}
table.dataTable tr.DTTT_selected.even td.sorting_1 {
  background-color: #B0BED9;
}
table.dataTable tr.DTTT_selected.even td.sorting_2 {
  background-color: #B0BED9;
}
table.dataTable tr.DTTT_selected.even td.sorting_3 {
  background-color: #B0BED9;
}
/*
 * COLLECTIONS
 * Drop down list (collection) styles
 */
div.DTTT_collection {
  width: 150px;
  padding: 8px 8px 4px 8px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: #F9FAFC;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  z-index: 2002;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}
div.DTTT_collection_background {
  z-index: 2001;
}
div.DTTT_collection button.DTTT_button,
div.DTTT_collection div.DTTT_button,
div.DTTT_collection a.DTTT_button {
  position: relative;
  left: 0;
  right: 0;
  display: block;
  float: none;
  margin-bottom: 4px;
  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  -ms-box-shadow: 1px 1px 3px #999;
  -o-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
}
/*
 * PRINTING
 * Print display styles
 */
.DTTT_print_info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 150px;
  margin-left: -200px;
  margin-top: -75px;
  text-align: center;
  color: #333;
  padding: 10px 30px;
  background: #ffffff;
  /* Old browsers */
  background: linear-gradient(to bottom, #ffffff 0%, #F9FAFC 89%, #F9FAFC 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f9f9f9', GradientType=0);
  /* IE6-9 */
  opacity: 0.95;
  border: 1px solid black;
  border: 1px solid rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}
.DTTT_print_info h6 {
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
  margin: 1em;
}
.DTTT_print_info p {
  font-size: 14px;
  line-height: 20px;
}
/*
 * Table styles
 */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  /*
   * Header and footer styles
   */
  /*
   * Body styles
   */
}
table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: 600;
}
table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 2px solid #1C4B84;
}
table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none;
}
table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111111;
}
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting {
  cursor: pointer;
  *cursor: hand;
}
table.dataTable thead .sorting {
  background: none !important;
  position: relative;
}
table.dataTable thead .sorting:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0dc";
  right: 5px;
  top: 7px;
  position: absolute;
}
table.dataTable thead .sorting_asc {
  background: none !important;
  position: relative;
}
table.dataTable thead .sorting_asc:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0de";
  right: 5px;
  top: 7px;
  position: absolute;
}
table.dataTable thead .sorting_desc {
  background: none !important;
  position: relative;
}
table.dataTable thead .sorting_desc:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0dd";
  right: 5px;
  top: 7px;
  position: absolute;
}
table.dataTable thead .sorting_asc_disabled {
  background: none !important;
  position: relative;
}
table.dataTable thead .sorting_asc_disabled:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0de";
  right: 5px;
  top: 7px;
  position: absolute;
  color: #F9FAFC;
}
table.dataTable thead .sorting_desc_disabled {
  background: none !important;
  position: relative;
}
table.dataTable thead .sorting_desc_disabled:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0dd";
  right: 5px;
  top: 7px;
  position: absolute;
  color: #F9FAFC;
}
table.dataTable tbody tr {
  background-color: white;
}
table.dataTable tbody tr.selected {
  background-color: #b0bed9;
}
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 10px;
}
table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-top: 1px solid #dddddd;
}
table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: none;
}
table.dataTable.cell-border tbody th,
table.dataTable.cell-border tbody td {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #dddddd;
}
table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
  border-top: none;
}
table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: #F9FAFC;
}
table.dataTable.stripe tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected {
  background-color: #abb9d3;
}
table.dataTable.hover tbody tr:hover,
table.dataTable.hover tbody tr.odd:hover,
table.dataTable.hover tbody tr.even:hover,
table.dataTable.display tbody tr:hover,
table.dataTable.display tbody tr.odd:hover,
table.dataTable.display tbody tr.even:hover {
  background-color: whitesmoke;
}
table.dataTable.hover tbody tr:hover.selected,
table.dataTable.hover tbody tr.odd:hover.selected,
table.dataTable.hover tbody tr.even:hover.selected,
table.dataTable.display tbody tr:hover.selected,
table.dataTable.display tbody tr.odd:hover.selected,
table.dataTable.display tbody tr.even:hover.selected {
  background-color: #a9b7d1;
}
table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3,
table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3 {
  background-color: #F9FAFC;
}
table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3,
table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3 {
  background-color: #acbad4;
}
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #f1f1f1;
}
table.dataTable.display tbody tr.odd > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #F9FAFC;
}
table.dataTable.display tbody tr.odd > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: whitesmoke;
}
table.dataTable.display tbody tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #a6b3cd;
}
table.dataTable.display tbody tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #a7b5ce;
}
table.dataTable.display tbody tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #a9b6d0;
}
table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #F9FAFC;
}
table.dataTable.display tbody tr.even > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #fbfbfb;
}
table.dataTable.display tbody tr.even > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #fdfdfd;
}
table.dataTable.display tbody tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #acbad4;
}
table.dataTable.display tbody tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #adbbd6;
}
table.dataTable.display tbody tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #afbdd8;
}
table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.display tbody tr.odd:hover > .sorting_1,
table.dataTable.display tbody tr.even:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
  background-color: #eaeaea;
}
table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.display tbody tr.odd:hover > .sorting_2,
table.dataTable.display tbody tr.even:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_2 {
  background-color: #ebebeb;
}
table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.display tbody tr.odd:hover > .sorting_3,
table.dataTable.display tbody tr.even:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr.even:hover > .sorting_3 {
  background-color: #eeeeee;
}
table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.display tbody tr.odd:hover.selected > .sorting_1,
table.dataTable.display tbody tr.even:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_1 {
  background-color: #a1aec7;
}
table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.display tbody tr.odd:hover.selected > .sorting_2,
table.dataTable.display tbody tr.even:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_2 {
  background-color: #a2afc8;
}
table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.display tbody tr.odd:hover.selected > .sorting_3,
table.dataTable.display tbody tr.even:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_3 {
  background-color: #a4b2cb;
}
table.dataTable.no-footer {
  border-bottom: 1px solid #111111;
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}
table.dataTable.compact thead th,
table.dataTable.compact thead td {
  padding: 5px 9px;
}
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
  padding: 5px 9px 3px 9px;
}
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px 5px 4px 9px;
}
table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}
table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center;
}
table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}
table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}
table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}
table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}
table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}
table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}
table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}
table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}
table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}
table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}
table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}
table.dataTable,
table.dataTable th,
table.dataTable td {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
  margin-top: 10px;
}
.dataTables_wrapper .dataTables_length {
  float: right;
  margin-top: 3px;
}
.dataTables_wrapper .dataTables_filter {
  float: left;
  text-align: right;
  margin-bottom: 10px;
}
.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}
.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}
.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #1C4B84 !important;
  margin: 0 0.0722rem;
  border: none;
  border-radius: 3px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #1C4B84 !important;
  color: white!important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: none;
  background-color: #585858;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
}
.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* IE10+ */
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333333;
}
.dataTables_wrapper .dataTables_scroll {
  clear: both;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #E8EDF2;
  margin-bottom: 0.722rem;
}
.dataTables_wrapper.no-footer div.dataTables_scrollHead table,
.dataTables_wrapper.no-footer div.dataTables_scrollBody table {
  border-bottom: none;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
.dataTables_wrapper header {
  background-color: #e4e4e4;
  padding: 10px 10px 0px;
  margin-bottom: 10px;
}
.dataTables_wrapper header a.search-toggler {
  display: block;
  float: left;
  margin-top: 4px;
  margin-left: 5px;
  border-bottom: 1px solid #d4d4d4;
  cursor: pointer;
}
.dataTables_wrapper header .advanced-controller {
  background-color: #d4d4d4;
  margin: 0 -10px 0px;
  clear: both;
  max-height: 0;
  overflow: hidden;
  transition: padding 0.3s;
  padding: 0 10px;
}
.dataTables_wrapper header .advanced-controller.active {
  padding: 10px;
  max-height: 9999px;
}
.dataTables_wrapper header a.group-cluster {
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}
@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}
button.dt-button,
div.dt-button,
a.dt-button {
  background: #F9FAFC;
  border-color: #526da4;
  border-radius: 0;
  box-shadow: none !important;
}
button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover {
  background: #e8ecf4;
}
button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
  background: #e8ecf4;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:active,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #E8EDF2 !important;
  box-shadow: none !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.next,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
  background-color: white!important;
  font-weight: bold;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled:hover {
  background-color: inherit!important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: #1C4B84 !important;
  color: white!important;
}
.dataTables_wrapper .dataTables_processing {
  width: 200px;
  position: absolute;
  height: 150px;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -100px;
  background: #E8EDF2;
  line-height: 150px;
  padding: 0;
  z-index: 50;
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
}
div.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  background-color: #5192c9;
  box-shadow: none;
}
div.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background: #808080;
  box-shadow: none;
  color: #fff;
}
div.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
div.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
div.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  background: #808080;
  box-shadow: none;
  color: #fff;
}
div.xdsoft_datetimepicker .xdsoft_calendar td:hover,
div.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  background: #5192c9 !important;
}
div.xdsoft_datetimepicker .xdsoft_calendar td,
.xdsoft_datetimepicker .xdsoft_calendar th {
  color: #808080;
}
div.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_scroller:hover {
  background: #ccc !important;
}
html {
  /* Overwrite jquery-ui.css */
  /* Component containers
  ----------------------------------*/
  /* Interaction states
  ----------------------------------*/
  /* Icons
  ----------------------------------*/
}
html .ui-button {
  padding-top: 6px;
  padding-bottom: 6px;
}
html .ui-button,
html .ui-button:link,
html .ui-button:visited,
html .ui-button:hover,
html .ui-button:active {
  margin-bottom: 3px;
}
html a.ui-button.ui-button-icon-only {
  border-left: 1px solid white;
}
html a.ui-button:active.ui-button-icon-only {
  border-left: 1px solid white;
  background: #fff;
}
html .ui-widget input,
html .ui-widget select,
html .ui-widget textarea,
html .ui-widget button.fa {
  font-family: FontAwesome;
}
html .ui-widget.ui-widget-content {
  border: 1px solid #e8e9e8;
}
html .ui-widget-content {
  border: 1px solid #e8e9e8;
  background: #fff;
  color: #000;
}
html .ui-widget-content a {
  color: #000;
}
html .ui-widget-header {
  border: 1px solid #e8e9e8;
  background: #fff;
  color: #222;
}
html .ui-widget-header a {
  color: #222;
}
html .ui-state-default,
html .ui-widget-content .ui-state-default,
html .ui-widget-header .ui-state-default,
html .ui-button,
html body .ui-button.ui-state-disabled:hover,
html body .ui-button.ui-state-disabled:active {
  border: 1px solid #1C4B84;
  background: #1C4B84;
  color: #fff;
}
html .ui-state-default a,
html .ui-state-default a:link,
html .ui-state-default a:visited,
html a.ui-button,
html a:link.ui-button,
html a:visited.ui-button,
html .ui-button {
  color: #fff;
}
html .ui-state-hover,
html .ui-widget-content .ui-state-hover,
html .ui-widget-header .ui-state-hover,
html .ui-state-focus,
html .ui-widget-content .ui-state-focus,
html .ui-widget-header .ui-state-focus,
html .ui-button:hover,
html .ui-button:focus {
  border: 1px solid #1C4B84;
  background: #1C4B84;
  color: #fff;
}
html .ui-state-hover a,
html .ui-state-hover a:hover,
html .ui-state-hover a:link,
html .ui-state-hover a:visited,
html .ui-state-focus a,
html .ui-state-focus a:hover,
html .ui-state-focus a:link,
html .ui-state-focus a:visited,
html a.ui-button:hover,
html a.ui-button:focus {
  color: #fff;
}
html .ui-state-active,
html .ui-widget-content .ui-state-active,
html .ui-widget-header .ui-state-active,
html a.ui-button:active,
html .ui-button:active,
html .ui-button.ui-state-active:hover {
  border: 1px solid #1C4B84;
  background: #1C4B84;
  color: #fff;
  text-decoration: none;
}
html .ui-state-highlight,
html .ui-widget-content .ui-state-highlight,
html .ui-widget-header .ui-state-highlight {
  border: 1px solid #f0a911;
  background: #fbf9ee;
  color: #363636;
}
html .ui-state-highlight .fa,
html .ui-widget-content .ui-state-highlight .fa,
html .ui-widget-header .ui-state-highlight .fa {
  color: #1C4B84;
}
html .ui-state-highlight a,
html .ui-widget-content .ui-state-highlight a,
html .ui-widget-header .ui-state-highlight a {
  color: #363636;
}
html .ui-state-error,
html .ui-widget-content .ui-state-error,
html .ui-widget-header .ui-state-error {
  border: 1px solid #CD4D44;
  background: #fef1ec;
  color: #CD4D44;
}
html .ui-state-error a,
html .ui-widget-content .ui-state-error a,
html .ui-widget-header .ui-state-error a {
  color: #CD4D44;
}
html .ui-state-error-text,
html .ui-widget-content .ui-state-error-text,
html .ui-widget-header .ui-state-error-text {
  color: #CD4D44;
}
html .a-ui-state-disabled,
html .ui-state-disabled,
html .ui-widget-content .ui-state-disabled,
html .ui-widget-header .ui-state-disabled {
  opacity: 0.35;
  filter: alpha(opacity=35);
  /* support: IE8 */
  background-image: none;
}
html .a-ui-state-disabled {
  cursor: text;
}
html .ui-icon,
html .ui-widget-content .ui-icon {
  background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_555555_256x240.png");
}
html .ui-widget-header .ui-icon {
  background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_555555_256x240.png");
}
html div.ui-widget p .ui-icon {
  margin-top: 0px;
}
html table .ui-widget {
  line-height: 16px;
}
html .ui-state-default .ui-icon {
  background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_ffffff_256x240.png");
}
html .ui-state-hover .ui-icon,
html .ui-state-focus .ui-icon,
html .ui-button:hover .ui-icon,
html .ui-button:focus .ui-icon {
  background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_ffffff_256x240.png");
}
html .ui-state-active .ui-icon,
html .ui-button:active .ui-icon {
  background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_555555_256x240.png");
}
html .ui-state-highlight .ui-icon,
html .ui-button .ui-state-highlight.ui-icon {
  background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_555555_256x240.png");
}
html .ui-state-error .ui-icon,
html .ui-state-error-text .ui-icon {
  background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_cc0000_256x240.png");
}
html .ui-button .ui-icon {
  background-image: url("/node_modules/jquery-ui-dist/images/ui-icons_ffffff_256x240.png");
}
html .ui-corner-all,
html .ui-corner-top,
html .ui-corner-left,
html .ui-corner-tl {
  border-top-left-radius: 0px;
}
html .ui-corner-all,
html .ui-corner-top,
html .ui-corner-right,
html .ui-corner-tr {
  border-top-right-radius: 0px;
}
html .ui-corner-all,
html .ui-corner-bottom,
html .ui-corner-left,
html .ui-corner-bl {
  border-bottom-left-radius: 0px;
}
html .ui-corner-all,
html .ui-corner-bottom,
html .ui-corner-right,
html .ui-corner-br {
  border-bottom-right-radius: 0px;
}
html .ui-widget-overlay {
  background: #aaa;
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* support: IE8 */
}
html .ui-widget-shadow {
  margin: -8px 0 0 -8px;
  padding: 8px;
  background: #aaa;
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* support: IE8 */
  border-radius: 0px;
}
html .ui-tabs {
  padding: 0px;
}
html .ui-tabs .ui-tabs-nav {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}
html .ui-tabs .ui-tabs-tab {
  border-bottom: 0;
}
/* reset all elements */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 13px;
  vertical-align: baseline;
  background: transparent;
}
/* html5 elements */
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
/* html / body */
html,
body {
  height: 100%;
}
html.touch {
  /* remove iOs tap highlight color */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
body {
  background-color: white;
}
wrapper {
  /* always show vertical scrollbar to prevent horizontal jumping */
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  overflow: auto !important;
}
/* set font */
body,
input,
select,
textarea,
pre {
  color: black;
  font-family: 'Open Sans', 'sans-serif';
  font-size: 13px;
  line-height: 1.5;
}
/* anchors */
a {
  color: #1C4B84;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:active {
  text-decoration: underline;
}
/* remove focus outline */
a:focus {
  outline: 0;
}
/* block images in links */
a img {
  border: 0;
  cursor: pointer;
}
/* set the same top margin for common content elements */
p,
ol,
figure,
table,
dl,
blockquote,
q {
  margin: 15px 0 0 0;
}
/* reset lists */
li {
  padding: 0;
  margin: 0;
}
ul.bulletlist li {
  font-size: 12px;
  line-height: 23px;
  padding-left: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
ul {
  padding: 0px 0px 0px 2px;
  margin: 0px;
  list-style-type: disc;
  list-style-position: inside;
}
/* table */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* table header cells */
table thead td {
  font-weight: normal;
  text-align: left;
}
table th {
  text-align: left;
}
/* table cells */
table th,
table td {
  min-height: 39px;
  padding: 2px 0;
  vertical-align: top;
}
pre {
  line-height: 22px;
  padding: 2px 0;
  vertical-align: top;
}
.ruletimes td {
  padding: 0px 0 4px 0;
  vertical-align: middle;
}
html.touch input[type="text"],
html.touch input[type="password"],
html.touch input[type="button"],
html.touch input[type="submit"],
html.touch select,
html.touch textarea {
  -webkit-appearance: none;
  border-radius: 0;
}
textarea {
  resize: vertical;
}
input:focus {
  outline-color: #4a95be;
}
input[type="text"]:disabled,
input[type="password"]:disabled {
  background-color: #eee;
}
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  margin: 0 5px 0 0;
  line-height: 20px;
  top: 1px;
}
label.radiolabel {
  margin-right: 5px;
}
/* super en subscript */
sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub {
  top: 0.4em;
}
/* textarea */
textarea {
  resize: vertical;
}
.ui-buttonset {
  white-space: nowrap;
}
/*
 * Chat styles
 */
.chat {
  margin: 0 auto;
  max-width: 800px;
}
.chat p {
  margin: 0 0 1em;
  border-radius: 2px;
  padding: 0.5em 1em;
  background: #F9FAFC;
  max-width: 85%;
  clear: both;
  position: relative;
}
.chat p.them {
  float: left;
}
.chat p.them::after {
  content: "";
  position: absolute;
  left: -0.5em;
  bottom: 0;
  width: 0.5em;
  height: 1em;
  border-right: 0.5em solid #F9FAFC;
  border-bottom-right-radius: 1em 0.5em;
}
.chat p.me {
  float: right;
  background-color: #E8EDF2;
}
.chat p.me::after {
  content: "";
  position: absolute;
  right: -0.5em;
  bottom: 0;
  width: 0.5em;
  height: 1em;
  border-left: 0.5em solid #E8EDF2;
  border-bottom-left-radius: 1em 0.5em;
}
.chat p.system {
  float: left;
  background-color: #70A3CF;
  color: #000;
}
.pointer {
  cursor: pointer;
}
/*****************************************************************************************************
 * Responsive
 *****************************************************************************************************/
@media (max-width: 960px) {
  /* prevent iOs font resizing on orientation change */
  html {
    -webkit-text-size-adjust: none;
  }
}
input[type="button"],
input[type="submit"],
button,
.button,
a._button,
.ui-widget-content a._button {
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  -webkit-transition: background 0.2s ease-in-out;
  background: #1C4B84;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  margin: 0;
  padding: 8px 16px;
  position: relative;
  text-decoration: none;
  transition: background 0.2s ease-in-out;
  vertical-align: top;
  height: 32px;
}
input[type="button"][disabled],
input[type="submit"][disabled],
button[disabled],
.button[disabled],
a._button[disabled],
.ui-widget-content a._button[disabled],
input[type="button"].disabled,
input[type="submit"].disabled,
button.disabled,
.button.disabled,
a._button.disabled,
.ui-widget-content a._button.disabled {
  color: #808080;
  background: #F9FAFC;
  cursor: not-allowed;
}
input[type="button"],
input[type="submit"] {
  line-height: 14px;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
a._button {
  margin-bottom: 3px;
}
form.generic input[type="radio"],
form.generic input[type="checkbox"] {
  top: 2px;
}
form.generic .field {
  padding: 0 0;
  position: relative;
}
form.generic .field label,
form.generic .field .label {
  float: left;
  position: relative;
  top: 0;
  width: 29%;
}
form.generic .field .label-disabled {
  color: #808080;
}
form.generic .field.inline label,
form.generic .field.inline .label {
  top: 10px;
}
form.generic .field.inline .label.label-checkbox {
  top: 0;
  height: 20px;
}
form.generic .field .input {
  padding: 0 0;
  float: left;
  position: relative;
  width: 71%;
}
form.generic .field .input label,
form.generic .field .input .label {
  cursor: pointer;
  display: block;
  float: none;
  top: 0;
  width: auto;
}
input[type=radio] + form.generic .field .input label,
input[type=radio] + form.generic .field .input .label,
input[type=checkbox] + form.generic .field .input label,
input[type=checkbox] + form.generic .field .input .label {
  display: inline-block;
}
form.generic .field.inline .input {
  padding: 2px 0;
  width: auto;
  margin-right: 10px;
}
form.generic .field.inline .input.cb {
  height: 36px;
  line-height: 36px;
}
form.generic .field.inline .input label {
  display: inline-block;
  margin-right: 20px;
}
form.generic .field.inline .input .label {
  display: inline-block;
  margin-right: 20px;
}
form.generic .field.inline .input .question {
  padding: 7px 8px;
}
form.generic .field .input.textarea {
  float: left;
  margin-right: 0;
  width: 71%;
}
form.generic .field .description {
  clear: both;
  font-size: 95%;
  line-height: normal;
  margin-left: 29%;
  padding-bottom: 10px;
  top: 13px;
  width: 71%;
}
form.generic .field textarea {
  width: 100%;
}
form.generic .field div.file-image {
  width: 71%;
}
form.generic .field .select {
  cursor: pointer;
  overflow: hidden;
}
form.generic .field .select:before {
  border-right: 1px solid #d3d2d2;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}
form.generic .field .select:after {
  border-color: #000000 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  content: "";
  cursor: default;
  margin-top: -3px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
}
form.generic .field .select select {
  width: 120%;
}
form.generic .form-actions {
  padding-left: 29%;
}
form.generic .file-input-wrapper {
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
}
form.generic .file-input-wrapper input[type="file"] {
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  cursor: pointer;
  font-size: 80px;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
form.generic .file-input-wrapper input.feedback[type="text"] {
  vertical-align: top;
  width: 88%;
}
form.generic .file-input-wrapper button {
  position: absolute;
  right: 0;
  top: 0;
}
form.generic .field.error > label {
  color: #CD4D44;
}
form.generic .field.error > .label {
  color: #CD4D44;
}
form.generic .field.error .description {
  color: #CD4D44;
}
form.generic .alert {
  background: #eeeeee;
  border-radius: 2px;
  border: 1px solid #cccccc;
  color: #000000;
  margin: 15px 0;
  padding: 10px;
  position: relative;
}
form.generic .alert-error {
  background: #F9FAFC;
  border-color: #CD4D44;
}
form.generic .alert-success {
  background: #F9FAFC;
  border-color: #CD4D44;
}
input[type="text"],
input[type="number"],
input[type="password"],
textarea,
select,
input[name=filtertext] {
  -moz-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  appearance: none;
  border: 1px solid #d3d2d2;
  box-sizing: border-box;
  margin: 0;
  max-width: 100%;
  padding: 7px 8px;
  background-color: white;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
form.generic.application .field textarea {
  min-height: 80px;
}
form.generic .field.error input[type="text"],
form.generic .field.error input[type="password"],
form.generic .field.error textarea,
form.generic .field.error select,
form.generic .field.error .select:before {
  border-color: #CD4D44;
  box-shadow: 0 0;
}
select {
  height: 32px;
}
form div.field.action.hide {
  /* form helper */
  display: none;
}
form .keyvalset {
  margin-bottom: 5px;
}
.form-inline-label {
  margin-left: 10px;
  margin-right: 2px;
}
/* 12 columns fluid grid */
.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6,
.grid_7,
.grid_8,
.grid_9,
.grid_10,
.grid_11,
.grid_12 {
  display: block;
  float: left;
  position: relative;
  margin: 0;
}
.row {
  max-width: 1024px;
  margin: 0 auto;
  box-sizing: border-box;
}
.grid_1 {
  width: 8.333333%;
}
.grid_2 {
  width: 16.666666%;
}
.grid_3 {
  width: 25%;
}
/* col 1 third */
.grid_4 {
  width: 33.333333%;
}
.grid_5 {
  width: 41.666666%;
}
/* col 1 half */
.grid_6 {
  width: 50%;
}
.grid_7 {
  width: 58.333333%;
}
/* col 2 thirds */
.grid_8 {
  width: 66.666666%;
}
.grid_9 {
  width: 75%;
}
.grid_10 {
  width: 83.333333%;
}
.grid_11 {
  width: 91.666666%;
}
.grid_12 {
  width: 100%;
}
.padding_12 {
  padding: 12%;
}
@media (max-width: 800px) {
  /* remove grid */
  .grid_1,
  .grid_2,
  .grid_3,
  .grid_4,
  .grid_5,
  .grid_6,
  .grid_7,
  .grid_8,
  .grid_9,
  .grid_10,
  .grid_11,
  .grid_12 {
    width: 100%;
  }
}
/* page content */
.page-content {
  background-color: #ffffff;
  padding: 40px;
  position: relative;
  max-width: 1024px;
  box-shadow: 0 0px 20px rgba(100, 100, 100, 0.1);
  box-sizing: border-box;
}
select {
  padding-right: 11px;
}
body {
  padding-bottom: 0 !important;
}
body * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* hide tables that are not yet datatabled*/
.js table.has-datatable {
  visibility: hidden;
}
.js table.has-datatable.dataTable {
  visibility: visible;
}
h1 {
  margin-bottom: 20px;
}
.print .dataTables_wrapper {
  margin-top: 0;
}
body.print .dataTables_close-print {
  display: block;
}
/* datatable buttons */
button.dt-button,
div.dt-button,
a.dt-button {
  padding: 3px 8px;
}
button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover {
  color: #1C4B84 !important;
}
a.buttons-collection {
  padding-right: 1px;
}
a.buttons-collection span {
  padding-right: 17px;
  background: url(/node_modules/datatables.net-dt/images/sort_desc_disabled.png) no-repeat right -4px;
}
a.buttons-collection:hover span {
  background-image: url(/node_modules/datatables.net-dt/images/sort_desc.png);
}
/* button popup */
div.dt-button-background {
  background: #fff;
  display: none !important;
}
div.dt-button-collection {
  background-color: #fff;
  padding: 3px 0;
  width: auto;
  border-radius: 0;
}
div.dt-button-collection button.dt-button,
div.dt-button-collection div.dt-button,
div.dt-button-collection a.dt-button {
  border: 0;
  background: #fff;
  box-shadow: 0 0;
  border-radius: 0;
}
div.dt-button-collection button.dt-button:hover,
div.dt-button-collection div.dt-button:hover,
div.dt-button-collection a.dt-button:hover {
  color: #1C4B84 !important;
  border: 0;
  background: none;
}
div.dt-button-collection a.dt-button.is_active {
  font-weight: 600;
}
table.dataTable tbody td {
  border-top: 1px solid #E8EDF2;
}
/* cellpadding */
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 10px;
}
table.dataTable thead th.sorting_asc,
table.dataTable thead td.sorting_asc,
table.dataTable thead th.sorting_desc,
table.dataTable thead td.sorting_desc,
table.dataTable thead th.sorting,
table.dataTable thead td.sorting {
  padding-right: 18px;
}
table.dataTable td.right,
table.dataTable th.right {
  text-align: right;
}
/* table header */
table.dataTable thead .sorting .sorting_asc .sorting_desc {
  color: #1C4B84;
}
table.dataTable thead th {
  vertical-align: middle;
}
/* hover over row */
/* sorting column */
table.dataTable tbody tr[role="row"].odd > .sorting_1,
table.dataTable tbody tr[role="row"].even > .sorting_1,
table.dataTable tbody tr[role="row"].odd > .sorting_2,
table.dataTable tbody tr[role="row"].even > .sorting_2,
table.dataTable tbody tr[role="row"].odd > .sorting_3,
table.dataTable tbody tr[role="row"].even > .sorting_3 {
  background-color: #F9FAFC;
}
/* all rows */
table.dataTable tbody tr.odd,
table.dataTable tbody tr.even {
  background-color: transparent;
}
/* group header */
table.dataTable tbody tr.group-header td {
  border: 0;
  color: #000;
  background-color: #CD4D44;
  cursor: pointer;
}
table.dataTable tbody tr.group-header td span.total {
  font-weight: normal;
}
/* group footer */
table.dataTable tbody tr.group-footer {
  background: transparent;
}
table.dataTable tbody tr.group-footer td.sum {
  border-top: 1px solid #000;
  font-weight: 600;
}
/* info box */
.dataTables_wrapper .dataTables_info {
  text-transform: none;
  font-size: 100%;
}
/* multi select custom search */
.dataTables_filter {
  margin: 0 20px 10px 0;
  float: left;
}
/* page nr selector */
.dataTables_length select {
  width: 60px;
}
/* toolbar */
.toolbar {
  background: #CD4D44;
  padding: 10px 10px 0 10px;
  margin-bottom: 10px;
}
.dt-button.fa {
  float: right;
  border: 0;
  background: none;
  font-size: 15px;
  position: relative;
}
.dt-button.fa:hover {
  background: none !important;
  border: 0 !important;
}
.dt-button.fa span {
  display: none;
}
.ios .toolbar select,
.toolbar input {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  height: 29px;
  line-height: 29px;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background-image: -webkit-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -moz-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -o-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: linear-gradient(#eeeeee 1%, #ffffff 15%);
  cursor: text;
  font-size: 100%;
  font-size: 13px;
  color: #000;
  font-family: 'Open Sans', 'sans-serif';
  line-height: normal;
}
.toolbar .select2-container--default .select2-selection--multiple {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  line-height: 29px;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background-image: -webkit-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -moz-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -o-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: linear-gradient(#eeeeee 1%, #ffffff 15%);
  cursor: text;
  font-size: 100%;
  font-size: 13px;
  color: #000;
  font-family: 'Open Sans', 'sans-serif';
  line-height: normal;
  border-radius: 0;
}
.toolbar .select2-container .select2-search--inline .select2-search__field {
  margin: 0;
  padding: 3px 5px 0px 5px;
}
.toolbar .select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
}
.dataTables_wrapper .toolbar .dataTables_length {
  margin-top: 0;
  margin-right: 5px;
}
.toolbar .select2-search {
  padding: 0;
}
.toolbar .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
}
.toolbar .select2-container .select2-selection--multiple {
  min-height: 29px;
}
.toolbar .multiple input {
  width: 100px;
}
.toolbar .multiple .between {
  display: inline-block;
  width: 20px;
  text-align: center;
}
.toolbar.cf .dataTables_advanced-search.is_open + div.dataTables_length {
  display: none;
}
div.dt-buttons {
  top: 11px;
  float: none;
}
div.dt-buttons .layout-button,
div.dt-buttons .layout-button:hover,
div.dt-buttons .layout-button:focus,
div.dt-buttons .buttons-collection,
div.dt-buttons .layout-button:hover,
div.dt-buttons .layout-buttonc:focus {
  float: left;
  height: 29px;
  margin: 0;
  padding: 0 5px;
  text-shadow: none;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: none;
  position: relative;
  top: -1px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 27px;
}
div.dt-buttons .layout-button span,
div.dt-buttons .layout-button:hover span,
div.dt-buttons .layout-button:focus span,
div.dt-buttons .buttons-collection span,
div.dt-buttons .layout-button:hover span,
div.dt-buttons .layout-buttonc:focus span {
  padding-right: 20px;
  margin-left: 10px;
  color: #333333;
  background: none;
}
div.dt-buttons .layout-button span:after,
div.dt-buttons .layout-button:hover span:after,
div.dt-buttons .layout-button:focus span:after,
div.dt-buttons .buttons-collection span:after,
div.dt-buttons .layout-button:hover span:after,
div.dt-buttons .layout-buttonc:focus span:after {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  right: 10px;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
  content: '';
}
.dataTables_wrapper .dataTables_info {
  margin-bottom: 10px;
  padding-top: 10px;
}
.dataTables_wrapper .dataTables_filter {
  margin: 0 10px 10px 0;
}
/* multi select custom filter */
.dataTables_advanced-search {
  margin: 0 10px 10px 0;
  padding-left: 10px;
  border-left: 1px solid #aaa;
  float: left;
  position: relative;
  overflow: hidden;
}
.dataTables_advanced-search.is_open {
  overflow: visible;
}
.dataTables_advanced-search .dataTables_advanced-search-trigger {
  display: inline-block;
  line-height: 29px;
  cursor: pointer;
  color: #000;
}
.dataTables_advanced-search .dataTables_advanced-search-trigger:hover,
.dataTables_advanced-search .dataTables_advanced-search-trigger:focus {
  text-decoration: underline;
  color: #1C4B84;
  white-space: nowrap;
}
.dataTables_advanced-search .dataTables_advanced-search-trigger.close {
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 20px;
  display: none;
  padding: 0 10px;
}
.dataTables_advanced-search.is_open .dataTables_advanced-search-trigger.close {
  display: block;
}
.dataTables_advanced-search .filter {
  line-height: 29px;
  padding-top: 10px;
  display: block;
  position: absolute;
  top: -200px;
}
.dataTables_advanced-search .filter span.input {
  float: right;
  margin-left: 10px;
  white-space: nowrap;
}
.dataTables_advanced-search input.error {
  border-color: #CD4D44;
}
.dataTables_advanced-search.is_open .filter {
  position: relative;
  top: 0;
}
.dataTables_advanced-search .filter:first-child {
  padding-top: 0;
}
.dataTables_advanced-search select {
  min-width: 226px;
}
.dataTables_advanced-search {
  float: right;
  margin-left: 10px;
}
/* mpn */
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #ddd !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #1C4B84 !important;
  color: white;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 24px;
  padding: 0.5rem 0.75rem;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #1C4B84;
  border-radius: 3px;
  margin-left: 2px;
  margin: 0 0.0722rem;
  border: none;
  background-color: #E8EDF2;
}
/* print */
.print .wrapper .nav {
  display: none;
}
.print .wrapper {
  margin: 0;
}
.print .toolbar.cf {
  display: none;
}
.print .dt-buttons {
  display: none;
}
.print .page-content {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  padding-top: 40px;
  margin: 0;
}
.print .page-content > * {
  display: none;
}
.print .page-content > .dataTables_wrapper {
  display: block;
}
.print .page-content > .dataTables_wrapper > * {
  display: none;
}
.print .page-content > .dataTables_wrapper > table {
  display: block;
  width: 100%;
}
.print .page-content > .dataTables_wrapper > table tr,
.print .page-content > .dataTables_wrapper > table td,
.print .page-content > .dataTables_wrapper > table th,
.print .page-content > .dataTables_wrapper > table tbody,
.print .page-content > .dataTables_wrapper > table thead {
  width: auto !important;
}
.print .page-top {
  display: none;
}
.dataTables_close-graph {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: none;
}
.dataTables_close-print {
  position: absolute;
  top: -40px;
  left: -40px;
  right: -40px;
  display: none;
  text-align: center;
}
@media only print {
  .dataTables_close-graph a,
  .dataTables_close-print a {
    display: none !important;
  }
}
.dataTables_close-graph a,
.dataTables_close-print a {
  display: block;
  padding: 10px 20px 0;
  text-decoration: none;
  color: #666;
}
.print .page-content > .dataTables_wrapper > .dataTables_close-graph,
.print .page-content > .dataTables_wrapper > .dataTables_close-print {
  display: block;
}
/* highcharts */
#chart {
  min-height: 400px;
}
#chart,
.showchart .page-content > * {
  display: none !important;
}
.showchart .page-content > .dataTables_close-graph {
  display: block !important;
}
ul li {
  line-height: 20px;
}
.showchart .page-content {
  display: table;
  width: 1024px;
}
.showchart .page-content > #chart {
  display: table-cell !important;
  vertical-align: middle;
}
.showchart .page-top {
  display: none;
}
.highcharts-container {
  margin: 0 auto;
}
/* highcharts popup */
.DTTT_print_info {
  height: auto;
  box-sizing: border-box;
}
.DTTT_print_info p {
  margin: 20px 0;
}
/* highcharts link to highcharts.com */
svg > text[text-anchor="end"]:last-child {
  display: none;
}
/* no-search column cells fade out when searching */
td.no-search {
  opacity: 0.5;
}
th.no-search {
  color: #777;
}
table.dataTable thead .sorting.no-search {
  color: #70B7CD;
}
.hide-desktop {
  display: none;
}
.hide-mobile {
  display: block;
}
@media (max-width: 800px) {
  .hide-desktop {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  .page-content {
    padding: 20px;
    width: 100%;
  }
}
@media (min-width: 800px) {
  html {
    overflow-y: scroll;
  }
}
* {
  margin: 0;
}
html,
body {
  height: 100%;
}
.wrapper {
  min-height: 100%;
  height: auto !important;
  /* This line and the next line are not necessary unless you need IE6 support */
  height: 100%;
  margin: 0 auto -65px;
  /* the bottom margin is the negative value of the footer's height */
}
.footer,
.push {
  height: 65px;
  /* .push must be the same height as .footer */
}
.footer {
  padding: 10px;
  text-align: center;
  position: relative;
}
.nav {
  background: #1C4B84;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  box-shadow: 0 0px 20px rgba(100, 100, 100, 0.1);
  color: #FFF;
}
.nav a {
  color: #FFF;
}
.nav ul.icons {
  text-align: right;
  float: right;
  margin: 0;
  margin-right: 10px;
  padding: 0;
}
.nav ul.icons li {
  list-style-type: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 10px;
}
.nav ul.icons li a {
  width: 16px;
  height: 16px;
  display: inline-block;
}
ul.icons {
  margin: 0;
  margin-right: 10px;
  padding: 0;
  display: inline-block;
}
ul.icons li {
  list-style-type: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 10px;
}
ul.icons li a {
  width: 16px;
  height: 16px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr,
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-radius: 0;
}
.kbn_active {
  font-weight: bold !important;
}
.push-380 {
  min-height: 486px;
  margin-top: 10px;
}
.push-480 {
  min-height: 480px;
  margin-top: 10px;
}
table.dataTable tbody tr.odd[role="row"] > .sorting_1,
table.dataTable tbody tr.even[role="row"] > .sorting_1,
table.dataTable tbody tr.odd[role="row"] > .sorting_2,
table.dataTable tbody tr.even[role="row"] > .sorting_2,
table.dataTable tbody tr.odd[role="row"] > .sorting_3,
table.dataTable tbody tr.even[role="row"] > .sorting_3 {
  background-color: inherit;
}
ul.navigation {
  margin: 0;
  padding: 0;
}
ul.navigation.single-column li {
  float: none;
}
ul.navigation li {
  background-color: #1C4B84;
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
  list-style-type: none;
  position: relative;
  width: 350px;
  float: left;
  margin-right: 10px;
}
ul.navigation li a {
  display: block;
  padding-left: 10px;
  height: 100%;
  margin-left: 40px;
  line-height: 40px;
  background-color: #F9FAFC;
  color: #000;
}
table.dataTable thead .sorting .sorting_asc .sorting_desc,
table.dataTable tbody a {
  color: #1C4B84;
}
.sidea {
  clear: none;
  width: 242px;
  float: left;
  margin: 15px 10px 0px 0px;
  position: relative;
}
#main2 {
  clear: both;
}
.clear {
  height: 0px;
  overflow: hidden;
  clear: both;
}
#side {
  clear: left;
  float: left;
}
#header.patient h1 {
  margin-bottom: 10px;
}
#header.patient h1 a {
  font-size: 13px;
  font-weight: 600;
}
#header.patient h1 a img {
  display: inline;
}
#header.patient span.meta {
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 600;
  display: block;
}
h1 a {
  position: relative;
}
h1 a img {
  display: inline;
}
.kenmerken {
  font-size: 10px;
}
.kenmerkenrood {
  font-size: 10px;
  color: #CD4D44;
}
.kenmerkengrijs {
  font-size: 10px;
  color: #808080;
}
.kenmerkengroen {
  font-size: 10px;
  color: #5CC082;
}
.postscript {
  font-size: 10px;
  color: #808080;
  margin-bottom: 1px;
}
.medicatieopm {
  color: #CD4D44;
}
.footernav {
  clear: both;
  padding-top: 15px;
  font-size: 32px;
}
.footernav a {
  color: #808080;
}
.footernav a:hover {
  color: #1C4B84;
}
#toolbar {
  padding: 0px;
  display: inline;
}
table.medicatie td {
  line-height: 24px;
  text-align: left;
  vertical-align: top;
  margin: 0px;
  padding: 0px;
  -webkit-text-size-adjust: none;
}
tr.sideline td {
  line-height: 14px;
  padding: 0 0 4px 0;
}
.patientnaam {
  font-weight: 600;
}
table td.aantalmedicatie {
  text-align: right;
  white-space: nowrap;
}
#extend_session_wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
}
#extend_session {
  width: 400px;
  height: 200px;
  position: absolute;
  margin-left: -200px;
  margin-top: -80px;
  top: 50%;
  left: 50%;
  background: #FFF;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 15px;
  text-align: center;
  line-height: 21px;
}
#extend_session span#session_time_remaining {
  font-family: 'Open Sans', 'sans-serif';
  padding: 5px;
  background-color: #eee;
}
div.gobitleft {
  width: 120px;
  display: inline-block;
}
.ajaxloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.3);
}
.ajaxloader img {
  z-index: 1000;
}
.ajaxloader div {
  width: 64px;
  height: 64px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
}
div.tdrdaily h3 {
  margin: 15px 0;
}
div.tdrdaily a.tdrdailypatient {
  width: 125px;
  display: inline-block;
  text-decoration: none;
  margin-right: 5px;
  margin-bottom: 10px;
  position: relative;
  height: 160px;
}
div.tdrdaily a.tdrdailypatient span.picture {
  background-size: contain;
  width: 125px;
  display: block;
  height: 125px;
  background-repeat: no-repeat;
  background-position: center center;
}
div.tdrdaily a.tdrdailypatient span.label {
  position: absolute;
  top: 130px;
  text-align: center;
  display: block;
}
.ui-tabs-panel {
  min-height: 433px;
}
.ui-tabs .ui-tabs-nav .ui-state-default {
  background: #F9FAFC;
  border-color: #E8EDF2;
}
.ui-tabs .ui-tabs-nav .ui-state-default .ui-tabs-anchor {
  color: #000;
}
.ui-tabs .ui-tabs-nav .ui-state-default.ui-state-active {
  background: #ffffff;
  border-color: #5579A3;
}
.menuset {
  z-index: 1;
  display: none;
}
.ui-menu {
  position: absolute;
  white-space: nowrap;
}
.ui-menu li.ui-menu-item {
  margin: 0;
  padding: 1px;
  border: 0;
  list-style-type: none;
}
.ui-menu li.ui-menu-item > * {
  display: block;
  padding: 3px 1em 3px 0.4em;
}
.ui-menu li.ui-menu-item.ui-state-focus {
  border: 1px solid #1C4B84;
  padding: 0px;
}
.filter-row .filter-add {
  font-size: 1.2em;
}
.filter-row .filter-item {
  padding-right: 10px;
}
.filter-row .filter-item select,
.filter-row .filter-item input[type=text] {
  width: 100%;
}
.row.add-filter {
  margin-top: 15px;
}
.row.add-filter .button.a-filter {
  float: right;
  width: 22px;
  height: 32px;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  top: 2px;
}
.row.add-filter .filter-options {
  padding: 10px;
  margin-right: 10px;
  background-color: #E8EDF2;
}
.datatableswrapper {
  width: 100%;
  display: none;
}
.medimomodal-wrapper {
  display: block;
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  bottom: -10px;
  left: 0;
  right: 0;
  min-height: 100%;
  width: 100%;
  z-index: 100;
}
.medimomodal {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow: hidden;
  width: 496px;
  max-width: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
}
.medimomodal.wide {
  width: 100%;
  max-width: 1920px;
}
.medimomodal-header {
  padding: 16px 20px;
  height: 45px;
  background: #0472B5;
  color: #FFF;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
}
.medimomodal-header h1,
.medimomodal-header h2,
.medimomodal-header h3,
.medimomodal-header h4 {
  font-size: inherit;
  padding: 0;
  margin: 10px 0px 5px 0px;
  font-weight: inherit;
  line-height: inherit;
}
.medimomodal-header .right {
  float: right;
  font-size: 120%;
}
.medimomodal-ajax-container {
  background: #fff;
}
.medimomodal-content {
  padding: 20px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  line-height: 18px;
  position: relative;
}
.medimomodal-footer {
  text-align: right;
  background: #FFF;
  display: block;
  position: relative;
  box-sizing: content-box;
  min-height: 32px;
  line-height: 32px;
  padding: 0 20px 20px 20px;
}
.medimomodal-footer a {
  display: inline-block;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  font-weight: normal;
  letter-spacing: normal;
  background: #0472B5;
  color: #fff;
  padding: 8px 16px;
  height: 32px;
  line-height: 16px;
}
.medimomodal-footer a.secondary {
  background: #61A4D3;
  color: #fff;
}
#login-table .smartcard {
  display: none;
}
/* nav */
li.is-active a {
  color: #CCC;
}
nav.top ul li {
  display: inline-block;
  list-style: none;
}
nav.top ul li a {
  display: block;
  padding: 10px 10px;
}
.nav ul.breadcrumbs {
  max-width: 550px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  float: left;
  margin: 0;
  padding: 0;
  margin-left: 10px;
}
.nav ul.breadcrumbs li {
  line-height: 50px;
  list-style-type: none;
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nav ul.breadcrumbs li .fa {
  font-size: 16px;
}
.nav ul.breadcrumbs li:before {
  content: '>';
  padding: 0 10px;
}
.nav ul.breadcrumbs li:first-child:before {
  content: '';
  padding: 0px;
}
.nav ul.breadcrumbs li:last-child {
  max-width: 300px;
}
#breadcrumb-mobile,
#menu-links-icon {
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}
.breadcrumb-mobile a,
.menu-links ul a {
  color: black;
}
#menu-links-icon {
  margin-right: 3px;
}
.menu-links {
  float: right;
  margin-right: 5px;
}
/* paragraphs are used to render the intro and linked paragraphs of a page */
.paragraphs {
  margin-top: 15px;
  /* same as common content elements from base.css */
}
.paragraphs:first-child {
  margin-top: 0;
}
.paragraphs ol,
.paragraphs ul {
  overflow: auto;
  /* prevent bullets from falling out when next to a floating img */
}
/* figures for images */
figure.img {
  margin-bottom: 10px;
}
figure.img.left {
  float: left;
  margin-right: 15px;
  max-width: 250px;
}
figure.img.right {
  float: right;
  margin-left: 15px;
  max-width: 250px;
}
figure.img img {
  display: block;
  width: 100%;
  height: auto;
}
/* clearfix for clearing elements that contain floating elements */
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
/* class for hiding anything */
.hidden {
  display: none;
}
/* float element left */
.push-left {
  float: left;
}
/* float element right */
.push-right {
  float: right;
}
/* classes to align text */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
/* hide redoute input */
input.redoute {
  display: none !important;
}
.form-status-new #amounts input,
.form-status-new #amountsfull input,
.form-status-new #minimumWaitingDays {
  width: 50px;
}
.form-status-new .hidden {
  display: none;
}
.form-status-new #amountsfull td {
  padding: 0 2px 4px;
  vertical-align: middle;
}
.form-status-new .calendar {
  display: inline-block;
  background-image: url(/public/assets/images/calendar.gif);
  background-size: contain;
  vertical-align: middle;
  width: 13px;
  height: 12px;
}
.form-status-new .kompas {
  display: inline-block;
  background-image: url(/public/assets/images/kompas.png);
  background-size: contain;
  vertical-align: text-bottom;
  width: 17px;
  height: 17px;
}
.form-status-new .dateTimeLabel {
  display: inline-block;
  width: 120px;
}
.form-status-new .amount {
  width: 50px;
}
.form-status-new .info {
  width: 571px;
}
.form-status-new .dateTimeBox {
  margin-top: 3px;
}
.form-status-checkboxes {
  float: left;
  line-height: 34px;
  margin-left: 15px;
}
.features {
  font-size: 10px;
  margin-bottom: 3px;
}
.features .warning {
  color: #CD4D44;
  font-size: inherit;
  font-weight: 600;
}
.statusrows .provisionalstatus a,
.statusrows .calculated {
  color: darkorange;
}
.orange {
  color: darkorange;
}
.red {
  color: #CD4D44;
}
.blue {
  color: #1C4B84;
}
.green {
  color: #5CC082;
}
.darkgrey {
  color: #808080;
}
.lightgrey {
  color: #F9FAFC;
}
.xdsoft_datetimepicker.xdsoft_noselect {
  display: none;
}
.lsp_form table {
  width: 100%;
  margin-top: 8px;
}
.lsp_form table thead {
  font-weight: bold;
  border: 1px solid #808080;
  background-color: #F9FAFC;
}
.lsp_form table thead th {
  padding: 5px;
}
.lsp-switch-box {
  float: left;
  height: 17px;
  margin: 3px 8px 0 0;
  width: 15px;
}
.lsp-switch {
  position: relative;
  border: 1px solid #ccc;
  box-sizing: content-box;
  cursor: pointer;
  font-size: 12px;
  height: 12px;
  text-align: center;
  width: 12px;
}
.lsp-switch-no {
  color: red;
  background-color: #fff9f9;
  border-color: #ff8787;
}
.lsp-switch-yes {
  color: green;
  background-color: #f9fff9;
  border-color: #5bb361;
}
.lsp-switch-neutral {
  color: grey;
  background-color: #F9FAFC;
}
.lsp-switch span {
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.25px;
  /* -0.25px correctly aligns the icon vertically in chrome, firefox, safari, IE 11 and Edge */
  width: 12px;
  height: 12px;
}
