// Color variables (appears count calculates by raw css)
@color0: #ff0000; // Appears 4 times
@color1: @mmdonkerblauw; // Appears 3 times
@color2: #d3d2d2; // Appears 2 times
@color3: #000000; // Appears 2 times

// Width variables (appears count calculates by raw css)
@width0: 100%; // Appears 4 times
@width3: 71%; // Appears 4 times
@width4: 29%; // Appears 2 times

input[type="button"], input[type="submit"], button, .button, a._button, .ui-widget-content a._button {
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  -webkit-transition: background 0.2s ease-in-out;
  background: @mmblauw;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  margin: 0;
  padding: 8px 16px;
  position: relative;
  text-decoration: none;
  transition: background 0.2s ease-in-out;
  vertical-align: top;
  height: 32px;

  &[disabled], &.disabled {
    color: @mmdonkergrijs;
    background: @mmlichtgrijs;
    cursor: not-allowed;
  }
}

input[type="button"], input[type="submit"]{
  line-height: 14px;
}

input {
  &::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }
}

a._button {
  margin-bottom: 3px;
}

form.generic {
  input[type="radio"], input[type="checkbox"] {
    top: 2px;
  }

  .field {
    padding: 0 0;
    position: relative;

    label, .label {
      float: left;
      position: relative;
      top: 0;
      width: @width4;
    }

    .label-disabled {
      color: @mmdonkergrijs
    }
    &.inline label, &.inline .label {
      top: 10px;
    }
    // Make label-checkbox just 1 line high
    &.inline .label.label-checkbox {
      top: 0;
      height: 20px;
    }
    .input {
      padding: 0 0;
      float: left;
      position: relative;
      width: @width3;
      label, .label {
        cursor: pointer;
        display: block;
        float: none;
        top: 0;
        width: auto;

        input[type=radio] + &, input[type=checkbox] + & {
          display: inline-block;
        }
      }
    }
    &.inline .input {
      padding: 2px 0;
      //min-height: 50px;
      //line-height: 35px;
      width: auto;
      margin-right: 10px;

      &.cb {
        height: 36px;
        line-height: 36px;
      }

      label {
        display: inline-block;
        margin-right: 20px;
      }
      .label {
        display: inline-block;
        margin-right: 20px;
      }
      .question {
        padding: 7px 8px;
      }
    }
    .input.textarea {
      float: left;
      margin-right: 0;
      width: @width3;
    }

    .description {
      clear: both;
      font-size: 95%;
      line-height: normal;
      margin-left: 29%;
      padding-bottom: 10px;
      top: 13px;
      width: @width3;
    }
    textarea {
      width: @width0;
    }
    div.file-image {
      width: @width3;
    }
    .select {
      cursor: pointer;
      overflow: hidden;
      &:before {
        border-right: 1px solid @color2;
        bottom: 0;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
      }
      &:after {
        border-color: @color3 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        content: "";
        cursor: default;
        margin-top: -3px;
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
      }
      select {
        width: 120%;
      }
    }
  }
  .form-actions {
    padding-left: 29%;
  }
  .file-input-wrapper {
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    input[type="file"] {
      background: none repeat scroll 0 0 transparent;
      border: 0 none;
      cursor: pointer;
      font-size: 80px;
      height: 100%;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: @width0;
      z-index: 999;
    }
    input.feedback[type="text"] {
      vertical-align: top;
      width: 88%;
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .field.error > label {
    color: @mmred;
  }
  .field.error > .label {
    color: @mmred;
  }
  .field.error {
    .description {
      color: @mmred;
    }
  }
  .alert {
    background: #eeeeee;
    border-radius: 2px;
    border: 1px solid #cccccc;
    color: @color3;
    margin: 15px 0;
    padding: 10px;
    position: relative;
  }
  .alert-error {
    background: @mmshade;
    border-color: @mmred;
  }
  .alert-success {
    background: @mmshade;
    border-color: @mmred;
  }
}

input[type="text"], input[type="number"], input[type="password"], textarea, select, input[name=filtertext] {
  -moz-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  appearance: none;
  border: 1px solid @color2;
  box-sizing: border-box;
  margin: 0;
  max-width: @width0;
  padding: 7px 8px;
  background-color: white;
}

input[type="number"]
{
  -moz-appearance:textfield;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

form.generic.application {
  .field {
    textarea {
      min-height: 80px;
    }
  }
}

form.generic .field.error input[type="text"], form.generic .field.error input[type="password"], form.generic .field.error textarea, form.generic .field.error select, form.generic .field.error .select:before {
  border-color: @mmred;
  box-shadow: 0 0;
}

select{
  height: 32px;
}

form div.field.action.hide {
  /* form helper */
  display: none;
}

form .keyvalset {
  margin-bottom: 5px;

  input.keyval {
    &.key {

    }

    &.val {

    }
  }
}

.form-inline-label {
  margin-left: 10px;
  margin-right: 2px;
}

form .multi-input{
}