.lsp_form {

  table {
    width: 100%;

    thead {
      font-weight:bold;
      border: 1px solid @mmdonkergrijs;
      background-color: @mmlichtgrijs;

      th {
        padding: 5px;
      }
    }

    margin-top: 8px;
  }
}