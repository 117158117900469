// Overwrites jquery.datetimepicker.css to use Medimo colors
// Overwritten lines are commented out
// CSS is honoured because of div.xdsoft_datetimepicker instead of .xdsoft_datetimepicker

div.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  //background: #ff8000;
  background-color: rgb(81, 146, 201);
  box-shadow: none;
}

div.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  //background: #33aaff;
  //box-shadow: #178fe5 0 1px 3px 0 inset;
  //color: #fff;
  background: @mmdonkergrijs;
  box-shadow: none;
  color: #fff;
}

div.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
div.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
div.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
  //background: #33aaff;
  //box-shadow: #178fe5 0 1px 3px 0 inset;
  //color: #fff;
  background: @mmdonkergrijs;
  box-shadow: none;
  color: #fff;
}

div.xdsoft_datetimepicker .xdsoft_calendar td:hover,
div.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
 // Copied line above and changed >div.xdsoft_time to >div.xdsoft_scroller
  //background: #ff8000 !important;
  background: rgb(81, 146, 201) !important;
}

div.xdsoft_datetimepicker .xdsoft_calendar td,.xdsoft_datetimepicker .xdsoft_calendar th {
  //color: #666;
  color: @mmdonkergrijs;
}

// Added line below to prevent timepicker scroller to highlight on hover (so it's the same as month and year picker scrollers' behaviour)
div.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_scroller:hover {
  background: #ccc !important;
}
