/* nav */
li.is-active a {
  color: #CCC;
}

nav.top ul li {
  display: inline-block;
  list-style: none;
}

nav.top ul li a {
  display: block;
  padding: 10px 10px;
}

.nav {
  ul.breadcrumbs {
    max-width: 550px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    margin: 0;
    padding: 0;
    margin-left: 10px;
    li {
      line-height: 50px;
      list-style-type: none;
      display: inline-block;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow:hidden;
      .fa {
        font-size: 16px;
      }
      &:before {
        content: '>';
        padding: 0 10px;
      }

      &:first-child {
        &:before {
          content: '';
          padding: 0px;
        }
      }

      &:last-child {
        max-width: 300px;
      }
    }
  }
}

#breadcrumb-mobile, #menu-links-icon {
  font-size:16px;
  cursor: pointer;
  margin-left: 10px;
}

.breadcrumb-mobile a, .menu-links ul a {
  color: black;
}

#menu-links-icon {
  margin-right: 3px;
}

.menu-links {
  float: right;
  margin-right: 5px;
}