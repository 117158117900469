/*
 * File:        TableTools.css
 * Description: Styles for TableTools 2
 * Author:      Allan Jardine (www.sprymedia.co.uk)
 * Language:    Javascript
 * License:     GPL v2 / 3 point BSD
 * Project:     DataTables
 *
 * Copyright 2009-2012 Allan Jardine, all rights reserved.
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * CSS name space:
 *   DTTT                  DataTables TableTools
 *
 * Style sheet provides:
 *   CONTAINER             TableTools container element and styles applying to all components
 *   BUTTON_STYLES         Action specific button styles
 *   SELECTING             Row selection styles
 *   COLLECTIONS           Drop down list (collection) styles
 *   PRINTING              Print display styles
 */

/*
 * CONTAINER
 * TableTools container element and styles applying to all components
 */
div.DTTT_container {
  position: relative;
  float: right;
  margin-bottom: 1em;
}

@media screen and (max-width: 640px) {
  div.DTTT_container {
    float: none !important;
    text-align: center;
  }

  div.DTTT_container:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}

button.DTTT_button,
div.DTTT_button,
a.DTTT_button {
  position: relative;
  display: inline-block;
  margin-right: 3px;
  padding: 5px 8px;
  border: 1px solid #999;
  cursor: pointer;
  *cursor: hand;
  font-size: 0.8660em;
  color: black !important;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;

  -webkit-box-shadow: 1px 1px 3px #ccc;
  -moz-box-shadow: 1px 1px 3px #ccc;
  -ms-box-shadow: 1px 1px 3px #ccc;
  -o-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;

  /* Generated by http://www.colorzilla.com/gradient-editor/ */
  background: #ffffff; /* Old browsers */
  background:linear-gradient(to bottom, #ffffff 0%, @mmlichtgrijs 89%, @mmshade 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f9f9f9', GradientType=0); /* IE6-9 */
}

/* Buttons are cunning border-box sizing - we can't just use that for A and DIV due to IE6/7 */
button.DTTT_button {
  height: 30px;
  padding: 3px 8px;
}

.DTTT_button embed {
  outline: none;
}

button.DTTT_button:hover,
div.DTTT_button:hover,
a.DTTT_button:hover {
  border: 1px solid #666;
  text-decoration: none !important;

  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  -ms-box-shadow: 1px 1px 3px #999;
  -o-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;

  background: @mmlichtgrijs; /* Old browsers */
  background: -webkit-linear-gradient(top, @mmlichtgrijs 0%, #e2e2e2 89%, #f4f4f4 100%); /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, @mmlichtgrijs 0%, #e2e2e2 89%, #f4f4f4 100%); /* FF3.6+ */
  background: -ms-linear-gradient(top, @mmlichtgrijs 0%, #e2e2e2 89%, #f4f4f4 100%); /* IE10+ */
  background: -o-linear-gradient(top, @mmlichtgrijs 0%, #e2e2e2 89%, #f4f4f4 100%); /* Opera 11.10+ */
  background:linear-gradient(to bottom, @mmlichtgrijs 0%, #e2e2e2 89%, #f4f4f4 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#f4f4f4', GradientType=0); /* IE6-9 */
}

button.DTTT_button:focus,
div.DTTT_button:focus,
a.DTTT_button:focus {
  border: 1px solid #426c9e;
  text-shadow: 0 1px 0 #c4def1;
  outline: none;

  background-color: #a3d0ef 100%;
  background-image: -webkit-linear-gradient(top, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  background-image: -moz-linear-gradient(top, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  background-image: -ms-linear-gradient(top, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  background-image: -o-linear-gradient(top, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  background-image:linear-gradient(to bottom, #a3d0ef 0%, #79ace9 65%, #a3d0ef 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#a3d0ef', EndColorStr='#a3d0ef');
}

button.DTTT_button:active,
div.DTTT_button:active,
a.DTTT_button:active {
  -webkit-box-shadow: inset 1px 1px 3px #999999;
  -moz-box-shadow: inset 1px 1px 3px #999999;
  box-shadow: inset 1px 1px 3px #999999;
}

button.DTTT_disabled,
div.DTTT_disabled,
a.DTTT_disabled {
  color: #999;
  border: 1px solid #d0d0d0;

  background: #ffffff; /* Old browsers */
  background:linear-gradient(to bottom, #ffffff 0%, @mmshade 89%, #fafafa 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fafafa', GradientType=0); /* IE6-9 */
}

/*
 * BUTTON_STYLES
 * Action specific button styles
 * If you want images - comment this back in

a.DTTT_button_csv,
a.DTTT_button_xls,
a.DTTT_button_copy,
a.DTTT_button_pdf,
a.DTTT_button_print {
    padding-right: 0px;
}

a.DTTT_button_csv span,
a.DTTT_button_xls span,
a.DTTT_button_copy span,
a.DTTT_button_pdf span,
a.DTTT_button_print span {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    padding-right: 30px;
}


a.DTTT_button_csv span { background: url(/public/assets/images/csv.png) no-repeat bottom right; }
a.DTTT_button_csv:hover span { background: url(/public/assets/images/csv_hover.png) no-repeat center right; }

a.DTTT_button_xls span { background: url(/public/assets/images/xls.png) no-repeat center right; }
a.DTTT_button_xls:hover span { background: #f0f0f0 url(/public/assets/images/xls_hover.png) no-repeat center right; }

a.DTTT_button_copy span { background: url(/public/assets/images/copy.png) no-repeat center right; }
a.DTTT_button_copy:hover span { background: #f0f0f0 url(/public/assets/images/copy_hover.png) no-repeat center right; }

a.DTTT_button_pdf span { background: url(/public/assets/images/pdf.png) no-repeat center right; }
a.DTTT_button_pdf:hover span { background: #f0f0f0 url(/public/assets/images/pdf_hover.png) no-repeat center right; }

a.DTTT_button_print span { background: url(/public/assets/images/print.png) no-repeat center right; }
a.DTTT_button_print:hover span { background: #f0f0f0 url(/public/assets/images/print_hover.png) no-repeat center right; }

 */

button.DTTT_button_collection span {
  padding-right: 17px;
  //background: url(/public/assets/images/collection.png) no-repeat center right;
}

button.DTTT_button_collection:hover span {
  padding-right: 17px;
  //background: #f0f0f0 url(/public/assets/images/collection_hover.png) no-repeat center right;
}

/*
 * SELECTING
 * Row selection styles
 */
table.DTTT_selectable tbody tr {
  cursor: pointer;
  *cursor: hand;
}

table.dataTable tr.DTTT_selected.odd {
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.odd td.sorting_1 {
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.odd td.sorting_2 {
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.odd td.sorting_3 {
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.even {
  background-color: #B0BED9;
}

table.dataTable tr.DTTT_selected.even td.sorting_1 {
  background-color: #B0BED9;
}

table.dataTable tr.DTTT_selected.even td.sorting_2 {
  background-color: #B0BED9;
}

table.dataTable tr.DTTT_selected.even td.sorting_3 {
  background-color: #B0BED9;
}

/*
 * COLLECTIONS
 * Drop down list (collection) styles
 */

div.DTTT_collection {
  width: 150px;
  padding: 8px 8px 4px 8px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: @mmlichtgrijs;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  z-index: 2002;

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;

  -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

div.DTTT_collection_background {
  //background: transparent url(/public/assets/images/background.png) repeat top left;
  z-index: 2001;
}

div.DTTT_collection button.DTTT_button,
div.DTTT_collection div.DTTT_button,
div.DTTT_collection a.DTTT_button {
  position: relative;
  left: 0;
  right: 0;

  display: block;
  float: none;
  margin-bottom: 4px;

  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  -ms-box-shadow: 1px 1px 3px #999;
  -o-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
}

/*
 * PRINTING
 * Print display styles
 */

.DTTT_print_info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 150px;
  margin-left: -200px;
  margin-top: -75px;
  text-align: center;
  color: #333;
  padding: 10px 30px;

  background: #ffffff; /* Old browsers */
  background:linear-gradient(to bottom, #ffffff 0%, @mmlichtgrijs 89%, @mmshade 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f9f9f9', GradientType=0); /* IE6-9 */

  opacity: 0.95;

  border: 1px solid black;
  border: 1px solid rgba(0, 0, 0, 0.5);

  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;

  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}

.DTTT_print_info h6 {
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
  margin: 1em;
}

.DTTT_print_info p {
  font-size: 14px;
  line-height: 20px;
}

